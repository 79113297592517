import {useCallback} from 'react';

import {
  jsonPointerResolver,
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils/dist';
import {JSONSchemaDefinition} from '@regulatory-platform/common-utils/dist/types';
import {useSelector} from '@xstate/react';
import {equals} from 'ramda';

import {useFormService} from '../FormProvider';

import {DSP_FormTableViewProps} from './FormTable.View';
import {
  DSP_FormTableColumnConfigList,
  DSP_FormTableRowType,
  DSP_FormTableColumnGroup,
  DSP_FormTableColumn,
} from './types';
import {isFormTableColumnGroup} from './utils';

export interface UseFormTableProps<ItemType extends Record<string, unknown>>
  extends Pick<DSP_FormTableViewProps<ItemType>, 'onImport' | 'RowRenderer'> {
  columnConfig: DSP_FormTableColumnConfigList<ItemType>;
  fieldRef: string;
  tableTitle?: string;
}

export function useFormTable<ItemType extends Record<string, unknown>>({
  fieldRef,
  columnConfig,
  tableTitle,
  ...props
}: UseFormTableProps<ItemType>): DSP_FormTableViewProps<ItemType> {
  const service = useFormService();

  const {schema, record} = useSelector(
    service,
    state => ({
      schema: state.context.schema,
      record: state.context.record,
    }),
    equals,
  );

  const itemsSchema = getSchema(schema, fieldRef);
  const fields = itemsSchema.properties ?? {};
  const tableLabel = `${
    tableTitle ?? itemsSchema['x-title'] ?? itemsSchema.title
  } table`;

  const columns = columnConfig.map(config =>
    isFormTableColumnGroup(config)
      ? createColumnGroup(config, fields)
      : createColumn(config as DSP_FormTableColumn<ItemType>, fields),
  );

  const rows: DSP_FormTableRowType[] = jsonPointerResolver<ItemType[]>(
    fieldRef,
  )(record).map(() => ({
    fieldRefPath: fieldRef,
  }));

  const onAddRow = useCallback(
    () =>
      service.send({
        fieldRef,
        type: 'ADD_ITEM',
      }),
    [fieldRef, service],
  );

  const onRemoveRow = useCallback(
    (index: number) =>
      service.send({
        index,
        fieldRef,
        type: 'REMOVE_ITEM',
      }),
    [fieldRef, service],
  );

  return {
    ...props,
    columns,
    rows,
    onAddRow,
    onRemoveRow,
    tableLabel,
  };
}

function getSchema(schema: JSONSchemaRecord, baseFieldRef: string) {
  const fieldSchema = schemaJsonPointerResolver(baseFieldRef)(schema) as
    | JSONSchemaRecord
    | undefined;

  const itemsSchema = fieldSchema?.['x-items'] as JSONSchemaRecord | undefined;

  if (!fieldSchema || !itemsSchema) {
    throw new Error(
      `Field ref '${baseFieldRef}' does not point to an array property.`,
    );
  }

  return itemsSchema;
}

function createColumnGroup<ItemType>(
  config: DSP_FormTableColumnGroup<ItemType>,
  fields: {[key: string]: JSONSchemaDefinition},
): DSP_FormTableColumnGroup<ItemType> {
  return {
    ...config,
    columns: config.columns.map(childConfig =>
      createColumn(childConfig, fields),
    ),
  };
}

function createColumn<ItemType>(
  config: DSP_FormTableColumn<ItemType>,
  fields: {[key: string]: JSONSchemaDefinition},
): DSP_FormTableColumn<ItemType> {
  const {
    field,
    title: configTitle,
    description: columnDescription,
    ...configProps
  } = config;

  const schema = fields[field] as JSONSchemaRecord | undefined;

  if (!schema) {
    throw new Error(
      `Field ${field.toString()} doesnt exist in schema properties.`,
    );
  }

  const {title: schemaTitle, description: schemaDescription} = schema;

  const title = configTitle ?? schemaTitle;
  const description = columnDescription ?? schemaDescription;

  return {
    ...configProps,
    title,
    field,
    description,
  };
}
