import {
  CommonInvocationContext,
  CommonValidatorResult,
  JSONSchemaRecord,
  ObjectType,
  validatorExec,
  ValidatorResult,
  UserProfile,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import getFormRecord from './getFormRecord';
import {FormStore, StoreShared} from './types';
import {APIMethodNames} from './authChecks';

export default function getFormStore<
  T extends ObjectType,
  S extends StoreShared = StoreShared,
>(
  schema: JSONSchemaRecord,
  validator: (
    context: CommonInvocationContext,
    schema: JSONSchemaRecord,
    record: ObjectType,
  ) => CommonValidatorResult,
  unTypedRecord?: T,
  shared?: S,
  userProfile?: UserProfile,
  protectedFieldsMethodName?: APIMethodNames,
  unTypedRecordIsNewRecord = false,
): FormStore<T, S> {
  const record = getFormRecord(
    schema,
    protectedFieldsMethodName,
  )(unTypedRecord);
  let origRecord =
    R.isNil(unTypedRecord) || unTypedRecordIsNewRecord ? undefined : record;
  let origSchema = undefined as undefined | JSONSchemaRecord;
  if (unTypedRecordIsNewRecord) {
    /**
     * Create blank record (default record) and obtain schema with x-origValue
     * set to blank record values.
     * @TODO this is unsafe as if the record contains arrays they will not be built out
     */
    origRecord = getFormRecord(schema, protectedFieldsMethodName)(undefined);
    const origModel = validatorExec(
      {
        record: origRecord,
        schema,
        origRecord: undefined,
        isClient: true,
        userProfile,
        shared,
        methodName: protectedFieldsMethodName,
      },
      validator,
      schema,
    ) as ValidatorResult;
    origSchema = origModel.validatedSchema;
  }

  /**
   * If unTypedRecordIsNewRecord then use the origSchema built above.
   * Otherwise setting the schema as third parameter to validatorExec results in
   * schema denormalised and arrays built out using buildSchema
   * Note: no cache available for initialisation of the store
   */
  const nextModel = validatorExec(
    {
      record,
      schema: origSchema ? origSchema : schema,
      origRecord,
      isClient: true,
      userProfile,
      shared,
      methodName: protectedFieldsMethodName,
    },
    validator,
    origSchema ? undefined : schema,
  ) as ValidatorResult;
  return {
    record: nextModel.processedRecord as T,
    schema: nextModel.validatedSchema as JSONSchemaRecord,
    shared: shared as S,
    methodName: protectedFieldsMethodName,
  };
}
