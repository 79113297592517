import React from 'react';

import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_ListItemProps
  extends Omit<MuiListItemProps, DSL_RestrictedVisualProps | 'component'> {
  display?: string;
}

export const DSL_ListItem = (props: DSL_ListItemProps) => {
  return (
    <MuiListItem component="li" sx={{display: props.display}} {...props} />
  );
};

DSL_ListItem.displayName = 'DSL_ListItem';

export {
  //
  /** @deprecated use DSL_ListItemProps instead*/
  DSL_ListItemProps as ListItemProps, //
  /** @deprecated use DSL_ListItem instead*/
  DSL_ListItem as ListItem,
};
