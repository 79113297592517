import React from 'react';

import {Paper as MuiPaper, PaperProps as MuiPaperProps} from '@mui/material';

export type DSL_PaperProps = MuiPaperProps;

export const DSL_Paper = (props: DSL_PaperProps) => {
  return <MuiPaper variant={'outlined'} {...props} />;
};

DSL_Paper.displayName = 'DSL_Paper';

export {
  //
  /** @deprecated use DSL_PaperProps instead*/
  DSL_PaperProps as PaperProps, //
  /** @deprecated use DSL_Paper instead*/
  DSL_Paper as Paper,
};
