import React from 'react';

import {
  DSL_BaseSummaryItem,
  DSL_BaseSummaryItemProps,
} from 'components/DesignSystem/Library';

import {
  DSP_UseSummaryItemRendererArgs,
  useSummaryItemRenderer,
} from './useSummaryItemRenderer';

export interface DSP_SummaryItemRendererProps
  extends Pick<DSL_BaseSummaryItemProps, 'ellipsisLines'>,
    DSP_UseSummaryItemRendererArgs {
  variant?: DSL_BaseSummaryItemProps['variant'];
  isSecondary?: DSL_BaseSummaryItemProps['isSecondary'];
  hideLabel?: boolean;
}

export const DSP_SummaryItemRenderer = ({
  variant = 'page',
  isSecondary,
  ellipsisLines,
  hideLabel,
  ...props
}: DSP_SummaryItemRendererProps) => {
  const {
    renderedValue: children,
    label,
    suffix,
  } = useSummaryItemRenderer(props);

  const labelToRender = variant === 'table' ? null : label;

  return (
    <DSL_BaseSummaryItem
      variant={variant}
      label={!hideLabel && labelToRender}
      suffix={suffix}
      isSecondary={isSecondary}
      ellipsisLines={ellipsisLines}
    >
      {children}
    </DSL_BaseSummaryItem>
  );
};

DSP_SummaryItemRenderer.displayName = 'DSP_SummaryItemRenderer';

export {
  /** @deprecated use DSP_SummaryItemRenderer instead*/
  DSP_SummaryItemRenderer as SummaryItemRenderer,
};
