import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/:accreditationId/employees',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationEmployees" */ './AccreditationEmployees'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accreditation Employees',
  menuIcon: Today,
  authorisation: {
    apiKey: 'AccreditationEmployeeMapping.findCustomer',
    policies: [{role: 'customerAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation',
      to: '/accreditation',
    },
  ],
};
