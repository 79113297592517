import {ReactNode} from 'react';
import {
  ContentKeyOrOptions,
  ContentMap,
  ContentValue,
  DomainContentMap,
  DomainMap,
  DomainMapWithDefault,
  GetContentOptions,
} from './types';
import {isUATOrProduction} from 'utils/global';

export interface ContentContainerOptions<
  Domains extends string,
  Keys extends string,
> {
  initialDomain: Domains;
  initialContent: DomainContentMap<Domains, Keys>;
  defaultDomain: Domains;
}

const canLog = !isUATOrProduction();

export class ContentContainer<Domains extends string, Keys extends string> {
  public readonly defaultDomain: Domains;

  private domain: Domains;
  private readonly domainContentMap: DomainContentMap<Domains, Keys>;

  public constructor({
    initialDomain: domain,
    initialContent: domainContentMap,
    defaultDomain,
  }: ContentContainerOptions<Domains, Keys>) {
    this.domain = domain;
    this.domainContentMap = domainContentMap;
    this.defaultDomain = defaultDomain;
  }

  public is(domain: Domains): boolean {
    return this.domain === domain;
  }

  public setDomain(domain: Domains): void {
    this.domain = domain;
  }

  public getDomain(): Domains {
    return this.domain;
  }

  public get(keyOrOptions: ContentKeyOrOptions<Domains, Keys>): ReactNode {
    const options = this.createGetContentOptions(keyOrOptions);
    const content = this.findByKey(options);

    if (Array.isArray(content)) {
      if (canLog) {
        // eslint-disable-next-line no-console
        console.error(
          `Content for key '${options.key}' is an array, use 'C.array' instead.`,
        );
      }
      return null;
    }

    return content;
  }

  public getArray(
    keyOrOptions: ContentKeyOrOptions<Domains, Keys>,
  ): ReactNode[] {
    const options = this.createGetContentOptions(keyOrOptions);
    const content = this.findByKey(options) ?? [];

    if (!Array.isArray(content)) {
      return [content];
    }

    return content;
  }

  public getString(keyOrOptions: ContentKeyOrOptions<Domains, Keys>): string {
    const options = this.createGetContentOptions(keyOrOptions);
    const content = this.findByKey(options);

    if (typeof content !== 'string') {
      if (canLog) {
        // eslint-disable-next-line no-console
        console.error(
          `Content for key '${options.key}' is not a string value.`,
        );
      }
      return '';
    }

    return content;
  }

  public fromMapWithDefault<ValueType>(
    map: DomainMapWithDefault<Domains, ValueType>,
  ): ValueType {
    return map[this.domain] ?? map.default;
  }

  public fromMap<ValueType>(
    map: DomainMap<Domains, ValueType>,
  ): ValueType | null {
    return map[this.domain] ?? null;
  }

  public registerContent(
    ...contentToRegisterList: DomainContentMap<Domains, Keys>[]
  ): () => void {
    contentToRegisterList.forEach(contentToRegister =>
      Object.entries(contentToRegister).forEach(domainEntry => {
        const [domain, content] = domainEntry as [Domains, ContentMap<Keys>];
        const domainContent = this.domainContentMap[domain as Domains];

        if (!domainContent) {
          return;
        }

        Object.entries(content).forEach(contentEntry => {
          const [key, value] = contentEntry as [Keys, ContentValue];

          if (domainContent?.[key as Keys]) {
            if (canLog) {
              // eslint-disable-next-line no-console
              console.error(
                `Content for key '${key}' in domain '${domain}' already exists. Content keys cannot be mutated via 'registerContent'.`,
              );
            }
            return;
          }

          domainContent[key as Keys] = value;
        });
      }),
    );

    return () =>
      contentToRegisterList.forEach(contentToRegister =>
        this.removeContent(contentToRegister),
      );
  }

  public removeContent(contentToRemove: DomainContentMap<Domains, Keys>): void {
    Object.entries(contentToRemove).forEach(domainEntry => {
      const [domain, content] = domainEntry as [Domains, ContentMap<Keys>];
      const domainContent = this.domainContentMap[domain as Domains];

      if (!domainContent) {
        return;
      }

      Object.keys(content).forEach(key => {
        delete domainContent[key as Keys];
      });
    });
  }

  public removeAllContent(): void {
    Object.keys(this.domainContentMap).forEach(domain => {
      this.domainContentMap[domain as Domains] = {};
    });
  }

  private findByKey({
    fallbackOrder = [],
    key,
    defaultValue,
    throwIfMissing,
  }: GetContentOptions<Domains, Keys>): ReactNode {
    for (const currentDomain of fallbackOrder) {
      if (this.domainContentMap[currentDomain]?.[key]) {
        return this.domainContentMap[currentDomain]?.[key];
      }
    }

    const missingMessage = `Content for key '${key}' is missing. Make sure it was registered before being used.`;

    if (throwIfMissing) {
      throw new Error(missingMessage);
    } else if (canLog) {
      console.error(missingMessage); // eslint-disable-line no-console
    }

    return defaultValue;
  }

  private createGetContentOptions(
    keyOrOptions: Keys | GetContentOptions<Domains, Keys>,
  ): GetContentOptions<Domains, Keys> {
    const defaultOptions = {
      fallbackOrder:
        this.domain === this.defaultDomain
          ? [this.defaultDomain]
          : [this.domain, this.defaultDomain],
      throwIfMissing: false,
    };

    return typeof keyOrOptions === 'string'
      ? {
          key: keyOrOptions,
          ...defaultOptions,
        }
      : {
          ...defaultOptions,
          ...keyOrOptions,
        };
  }
}
