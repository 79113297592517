import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal';
import * as R from 'ramda';
import React from 'react';
import {Navigate} from 'react-router-dom';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';
interface SubmissionRedirectProps {
  pagePath?: string;
}

const SubmissionRedirect = ({pagePath}: SubmissionRedirectProps) => {
  const {service} = useFormContext();
  const state = useSelector(service, R.identity);

  if (state.matches('submitted')) {
    const navigateTo = getStateMetaHead(state).navigateTo;
    if (!R.isNil(navigateTo) && navigateTo !== '') {
      return (
        <Navigate
          to={navigateTo + '/' + (pagePath || state.context.props.recordId)}
        />
      );
    }
  }

  return null;
};

export default SubmissionRedirect;
