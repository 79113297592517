import pako from 'pako';

/**
 * Compress Data (gzip)
 * @param {Object} data - Any object
 * @returns {string}
 */
export const compressData = (data: Object): string => {
  const stringData = JSON.stringify(data);
  const compressed = pako.gzip(stringData);

  // Convert Uint8Array to base64 string in batches to avoid call stack size issues
  const batchSize = 5000;
  let binaryString = '';

  for (let i = 0; i < compressed.length; i += batchSize) {
    const batch = compressed.subarray(i, i + batchSize);
    const numberArray = Array.from(batch);
    binaryString += String.fromCharCode.apply(null, numberArray);
  }

  // Base64 encode to safely transmit binary data
  return btoa(binaryString);
};
