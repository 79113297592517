/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as R from 'ramda';
import {FormEventObject, SearchStoreMachineContext} from 'utils/stores/types';
import {createMachine, Interpreter, MachineConfig, State} from 'xstate';
import machineDefinition from './searchMachine.json';

export interface SearchMachineStateSchema {
  states: {
    userInput: {
      states: {
        idle: {};
        searching: {};
        error: {};
      };
    };
    finished: {};
  };
}
export type SearchMachineService = Interpreter<
  SearchStoreMachineContext,
  SearchMachineStateSchema,
  FormEventObject
>;
export type SearchMachineState = State<
  SearchStoreMachineContext,
  FormEventObject,
  SearchMachineStateSchema
>;

export type SearchMachineConfig = MachineConfig<
  SearchStoreMachineContext,
  SearchMachineStateSchema,
  FormEventObject
>;

export default function getMachine(config = {} as SearchMachineConfig) {
  return createMachine<SearchStoreMachineContext, FormEventObject>(
    R.mergeDeepRight(
      machineDefinition as SearchMachineConfig,
      config,
    ) as SearchMachineConfig,
  );
}
