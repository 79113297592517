import InfoIcon from '@mui/icons-material/InfoOutlined';
import Roadmap from 'containers-information/Roadmap';
import AuditorsPublic from 'containers-accreditation/Auditors/Public';
import content from 'containers-accreditation/content';

export default {
  content,
  routes: [AuditorsPublic, Roadmap],
  path: '/information',
  menuTitle: 'Information Hub',
  menuDescription: 'General support information and roadmap',
  showChildRoutesInMenu: true,
  menuIcon: InfoIcon,
};
