import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/assets/:assetDetailId/vcbRefVehicle/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NewAssetDetailAratRefVehicleGateway" */ './NewAssetDetailAratRefVehicleGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add new asset reference vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailAratRefVehicleWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialSupervisor',
      },
      {
        role: 'partnerSpatialSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Assets',
      to: '../../..',
    },
    {
      content: 'Asset',
      to: '../..',
      state: {'Manage asset': 'Capability'},
    },
  ],
};
