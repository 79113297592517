import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';

export default {
  path: '/registration/jurisdictionalContacts',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "JurisdictionalContacts" */ './JurisdictionalContacts'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Jurisdictional contacts',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
