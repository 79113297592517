import {UserProfile} from '@regulatory-platform/common-utils';
import {getSessionType} from 'utils/global';
import AutoLogout from 'app/router/components/AutoLogout';
import ContainerRouter from 'app/router/components/ContainerRouter';
import AuthenticatedLayout from 'components/layout/AuthenticatedLayout';
import Loading from 'components/templates/content/Loading';
import React, {FC, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {AuthStoreService, AuthStoreState} from 'utils/machines/authMachine';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';
import {authenticatedContainers} from '../containerRegistry';
import rootContainer from 'containers/index';
import RedirectRoute from './RedirectRoute';
import {DSP_ErrorBoundary} from 'components/DesignSystem/Portal';
import {RouteElement} from './RouteElement';

type AuthenticatedRouterProps = {
  authState: AuthStoreState;
  authService: AuthStoreService;
  iframeMode: boolean;
};

const AuthenticatedRouter: FC<AuthenticatedRouterProps> = ({
  authService,
  authState,
  iframeMode,
}: AuthenticatedRouterProps) => {
  const userProfile = authState.context.userProfile as UserProfile;

  if (
    authState.matches('authenticated.changingAccount') ||
    authState.matches('loggingOut')
  ) {
    return (
      <AuthenticatedLayout userProfile={userProfile} iframeMode={iframeMode}>
        <Loading title={getStateMetaHead(authState).message} />
      </AuthenticatedLayout>
    );
  }
  return (
    <AuthenticatedLayout userProfile={userProfile} iframeMode={iframeMode}>
      <DSP_ErrorBoundary>
        {getSessionType() === 'local' ? null : (
          <AutoLogout authService={authService} />
        )}
        <Suspense fallback={<Loading />}>
          <Routes>
            {rootContainer.routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                element={<RouteElement {...route} />}
              />
            ))}
            {authenticatedContainers.map((container, i) => (
              <Route
                key={i}
                path={container.path + '/*'}
                element={
                  <ContainerRouter
                    userProfile={userProfile}
                    containerConfig={container}
                  />
                }
              />
            ))}
            {/**
             * Redirects
             */}
            <Route
              path="/spatial/*"
              element={<RedirectRoute from="/spatial/" to="/access/" />}
            />
            {/**
             * Catch all
             */}
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </DSP_ErrorBoundary>
    </AuthenticatedLayout>
  );
};

export default AuthenticatedRouter;
