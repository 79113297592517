import {useCallback, useMemo} from 'react';

import {
  CommonInvocationContext,
  CommonValidatorResult,
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils/dist';
import {
  JSONSchemaDefinition,
  ObjectType,
} from '@regulatory-platform/common-utils/dist/types';
import {useInterpret, useSelector} from '@xstate/react';
import {equals, pick} from 'ramda';
import {FileRejection} from 'react-dropzone';
import {assign} from 'xstate';

import {downloadBlobUrl} from '../../../utils/downloadFile';

import {DSP_ImportFromCsvProps} from './ImportFromCsv';
import {importFromCsvMachine} from './machines/importFromCsvMachine';
import {
  CsvAcceptedEvent,
  CsvColumn,
  CsvRow,
  ImportCsvColumnConfig,
  ImportCsvColumnConfigList,
  ImportFromCsvMachineContext,
  ImportFromCsvMachineEvents,
  SetErrorEvent,
} from './machines/types';

export interface UseImportFromCsvDialogProps<
  RecordType extends Record<string, unknown>,
> {
  schema: JSONSchemaRecord;
  columnConfig: ImportCsvColumnConfigList<RecordType>;
  onImport: (records: RecordType[]) => void;
  fieldRef?: string;
  maxRows?: number;
  nameOfData?: string;
  templateFileName?: string;
  validator?: (
    context: CommonInvocationContext,
    schema: JSONSchemaRecord,
    record: ObjectType,
  ) => CommonValidatorResult;
}

export interface UseImportFromCsvDialogValues extends DSP_ImportFromCsvProps {
  openDialog: () => void;
}

const defaultValidator = (
  _: CommonInvocationContext,
  processedSchema: JSONSchemaRecord,
  processedRecord: ObjectType,
): CommonValidatorResult => ({
  processedRecord,
  processedSchema,
});

export function useImportFromCsv<RecordType extends Record<string, unknown>>({
  validator = defaultValidator,
  maxRows = 100,
  nameOfData: overrideName,
  templateFileName = 'Import_Template.csv',
  schema,
  onImport,
  fieldRef,
  columnConfig,
  ...props
}: UseImportFromCsvDialogProps<RecordType>): UseImportFromCsvDialogValues {
  const csvService = useInterpret<
    ImportFromCsvMachineContext,
    ImportFromCsvMachineEvents
  >(importFromCsvMachine, {
    context: {
      maxRows,
      fieldRef,
      validator,
      csv: [],
      rows: {},
      columns: [],
      omittedRowCount: 0,
      schema: getSchema(schema, fieldRef),
    },
    actions: {
      init: useMemo(
        () => createInit<RecordType>(columnConfig, fieldRef),
        [columnConfig, fieldRef],
      ),
    },
    services: {
      importData: useMemo(
        () => createImportData<RecordType>(onImport, fieldRef),
        [fieldRef, onImport],
      ),
    },
  });

  const {exampleCsvFile, ...stateDerivedProps} = useSelector(
    csvService,
    state => {
      const context = state.context;
      const rows: CsvRow[] = Object.values(state.context.rows);
      return {
        ...calculateRowCounts(rows),
        open: state.matches('open'),
        columns: state.context.columns,
        formError: context.formErrorMessage,
        exampleCsvFile: context.exampleCsvFile,
        omittedRowCount: context.omittedRowCount,
        rowMachines: rows.map(row => row.machine),
        step: state.matches('open.idle')
          ? ('SELECT' as const)
          : ('EDIT' as const),
        isLoading:
          state.matches('open.parsing') || state.matches('open.importing'),
      };
    },
    equals,
  );

  const nameOfData = useMemo(() => {
    const fieldSchema = fieldRef
      ? schemaJsonPointerResolver(fieldRef, 'x-items')(schema)
      : schema;

    return (
      overrideName ?? fieldSchema['x-title'] ?? fieldSchema.title ?? 'Record'
    );
  }, [fieldRef, overrideName, schema]);

  const actions = useMemo(
    () => ({
      onImport: () => csvService.send('IMPORT'),
      onClose: () => csvService.send('CLOSE_DIALOG'),
      onReset: () => csvService.send('RESET'),
      openDialog: () => csvService.send('OPEN_DIALOG'),
      onDropRejected: (rejections: FileRejection[]) =>
        csvService.send({
          type: 'DROP_REJECTED',
          errorMessage: rejections[0].errors[0].message,
        } as SetErrorEvent),
      onDropAccepted: (files: File[]) =>
        csvService.send({
          type: 'DROP_ACCEPTED',
          file: files[0],
        } as CsvAcceptedEvent),
    }),
    [csvService],
  );

  const onDownloadFormat = useCallback(
    () => downloadBlobUrl(exampleCsvFile, templateFileName),
    [exampleCsvFile, templateFileName],
  );

  return {
    ...props,
    ...stateDerivedProps,
    ...actions,
    maxRows,
    csvService,
    nameOfData,
    onDownloadFormat,
  };
}

function getSchema(schema: JSONSchemaRecord, fieldRef: string | undefined) {
  if (!fieldRef) {
    return schema;
  }

  const pickedSchema: JSONSchemaRecord = {
    ...schema,
    definitions: undefined,
    properties: pick([fieldRef.replace('#/', '')], schema.properties),
  };

  return pickedSchema;
}

function calculateRowCounts(rows: CsvRow[]) {
  return rows.reduce(
    (accumulator, row) => {
      if (!row.isValid) {
        accumulator.invalidRowCount += 1;
      }
      if (row.isSelected) {
        accumulator.selectedRowCount += 1;
      }
      return accumulator;
    },
    {invalidRowCount: 0, selectedRowCount: 0},
  );
}

function createInit<RecordType extends Record<string, unknown>>(
  columnConfigList: ImportCsvColumnConfigList<RecordType>,
  fieldRef: string | undefined,
) {
  return assign(
    (
      context: ImportFromCsvMachineContext,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: ImportFromCsvMachineEvents,
    ) => {
      const schema = fieldRef
        ? schemaJsonPointerResolver(fieldRef, 'x-items')(context.schema)
        : context.schema;
      const fields = schema.properties ?? {};

      const columns: CsvColumn[] = columnConfigList.map(columnConfig =>
        createCsvColumn(fieldRef, columnConfig, fields),
      );

      const exampleCsvFile = new Blob(
        [
          `${columns.map(({title}) => title)}\n${columns.map(
            ({examples}) => examples,
          )}`,
        ],
        {
          type: 'text/csv',
        },
      );

      return {
        ...context,
        columns,
        exampleCsvFile,
      };
    },
  );
}

function createCsvColumn<RecordType>(
  fieldRef: string | undefined,
  columnConfig: ImportCsvColumnConfig<RecordType>,
  fields: {[key: string]: JSONSchemaDefinition},
): CsvColumn {
  const {
    field,
    width,
    title: configTitle,
    examples: configExample,
    description: columnDescription,
  } = columnConfig;
  const fieldSchema = fields[field] as JSONSchemaRecord;
  const {
    examples: schemaExamples,
    title: schemaTitle,
    description: schemaDescription,
  } = fieldSchema;

  const title = configTitle ?? schemaTitle;
  const examples = `"${
    configExample?.toString() ?? schemaExamples?.toString() ?? ''
  }"`;
  const description = columnDescription ?? schemaDescription;

  return {
    width,
    title,
    examples,
    fieldRef,
    fieldSchema,
    description,
    fieldName: field as string,
  };
}

function createImportData<RecordType extends Record<string, unknown>>(
  onImport: (records: RecordType[]) => void,
  fieldRef: string | undefined,
) {
  return async (context: ImportFromCsvMachineContext) => {
    const selectedRecords = Object.entries(context.rows)
      .filter(([, row]) => row.isSelected)
      .sort(([indexA], [indexB]) => Number(indexA) - Number(indexB))
      .flatMap(([, {record}]) =>
        fieldRef ? record[fieldRef?.replace('#/', '')] : record,
      );

    try {
      onImport(selectedRecords as RecordType[]);
    } catch (error) {
      return {
        errorMessage: 'An unexpected error occurred while importing the data.',
      } as SetErrorEvent;
    }
  };
}
