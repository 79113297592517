import React, {useContext} from 'react';

import {FormEventObject, FormMachineContext} from 'utils/stores/types';
import {Interpreter, StateSchema} from 'xstate';

export interface DSP_FormContextProps<
  T extends FormMachineContext = FormMachineContext,
> {
  service: Interpreter<T, StateSchema, FormEventObject>;
  parentService?: Interpreter<T, StateSchema, FormEventObject>;
  otherProps?: {[key: string]: unknown};
  isValidForm: boolean;
  hasChangesToSave: boolean;
  readOnly: boolean;
  recordId?: string | number | null;
  canSave?: boolean;
  isCreate?: boolean;
  submitConfirmMessage?: string;
}
export const DSP_FormContext = React.createContext({} as DSP_FormContextProps);

export function useFormContext(): DSP_FormContextProps {
  return useContext(DSP_FormContext);
}

export {
  //
  /** @deprecated use DSP_FormContextProps instead*/
  DSP_FormContextProps as FormContextProps, //
};
