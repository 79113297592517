import React from 'react';

import {useFormContext} from '../FormProvider';

import {DSP_FormSaveConfirmationDialog} from './FormSaveConfirmationDialog';
import {DSP_FormSaveDialogSavingToArchive} from './FormSaveDialogSavingToArchive';
import {useFormSaveDialog} from './useFormSaveDialog';

export interface DSP_FormSaveDialogProps {
  onClose: () => void;
  open: boolean;
}

export const DSP_FormSaveDialog = (props: DSP_FormSaveDialogProps) => {
  const {isSavingToArchive} = useFormSaveDialog();
  const {submitConfirmMessage} = useFormContext();
  if (isSavingToArchive) {
    return (
      <DSP_FormSaveDialogSavingToArchive
        submitConfirmMessage={submitConfirmMessage}
        {...props}
      />
    );
  } else {
    return (
      <DSP_FormSaveConfirmationDialog
        submitConfirmMessage={submitConfirmMessage}
        {...props}
      />
    );
  }
};

export {
  //
  /** @deprecated use DSP_FormSaveDialogProps instead*/
  DSP_FormSaveDialogProps as FormSaveDialogProps, //
  /** @deprecated use DSP_FormSaveDialog instead*/
  DSP_FormSaveDialog as FormSaveDialog,
};
