import {
  CommonValidator,
  ObjectType,
  removeArrayItemFromSchema,
  schemaJsonPointerResolver,
  updateFieldPropOnSubSchema,
  insertNewArrayItemIntoSchema,
  jsonPointerResolver,
  validatorExec,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext, FormRecord} from './types';

export default function onRemoveAllArrayItemsFromSchema<T extends FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
): FormMachineContext<T> {
  const {fieldRef} = event;
  const {record: origRecord, shared} = context;
  let {schema: origSchema} = context;
  let record = origRecord as ObjectType,
    schema = origSchema;
  const minItems = R.defaultTo(
    0,
    schemaJsonPointerResolver<number | undefined>(
      fieldRef,
      'minItems',
    )(origSchema),
  );
  const arrayItem = jsonPointerResolver(fieldRef)(
    context.record,
  ) as ObjectType[];

  //remove all rows upto and including minItems
  if (!R.isNil(arrayItem) && arrayItem.length > minItems - 1) {
    arrayItem.forEach((prop, idx) => {
      if (minItems - 1 <= idx) {
        [record, schema] = removeArrayItemFromSchema(
          fieldRef,
          arrayItem.length - (idx + 1),
        )(record as ObjectType, schema);
      }
    });
  }
  //if minItems > 0 then add back 1
  if (minItems > 0) {
    origSchema = updateFieldPropOnSubSchema(
      origSchema,
      fieldRef + '/-1',
      'x-valid/touched',
      false,
      true,
    )(origSchema);
    origSchema = updateFieldPropOnSubSchema(
      origSchema,
      fieldRef + '/-1',
      'x-origValue',
      undefined,
      true,
    )(origSchema);
    [record, schema] = insertNewArrayItemIntoSchema(fieldRef)(record, schema);
  }

  const result = validatorExec(
    {
      record,
      origRecord,
      schema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared: R.mergeRight(R.defaultTo({}, shared), {fieldRef}),
    },
    validator,
  );
  const nextContext = context;
  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== result.validatedSchema) {
    nextContext.schema = result?.validatedSchema;
  }
  if (!R.isNil(context.props) && !R.isNil(context.props.apiError)) {
    nextContext.props.apiError = undefined;
  }
  return nextContext;
}
