import React from 'react';

import {DSL_RefreshIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_RefreshIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_RefreshIconButton = (
  props: DSL_RefreshIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="close" {...props}>
      <DSL_RefreshIcon />
    </DSL_BaseIconButton>
  );
};

DSL_RefreshIconButton.displayName = 'DSL_RefreshIconButton';

export {
  //
  /** @deprecated use DSL_RefreshIconButtonProps instead*/
  DSL_RefreshIconButtonProps as RefreshIconButtonProps, //
  /** @deprecated use DSL_RefreshIconButton instead*/
  DSL_RefreshIconButton as RefreshIconButton,
};
