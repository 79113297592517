import React, {useContext} from 'react';
import {FormContextProps} from 'utils/stores/types';

const FormContext = React.createContext({} as FormContextProps);

export function useFormContext(): FormContextProps {
  return useContext(FormContext);
}

export function useFormService(): FormContextProps['service'] {
  const {service} = useFormContext();
  return service;
}

export default FormContext;
