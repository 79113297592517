import {
  CommonValidator,
  removeArrayItemFromSchema,
  validatorExec,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext, FormRecord} from './types';

export default function onRemoveArrayItemFromSchema<T extends FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
): FormMachineContext<T> {
  const {fieldRef, index} = event;
  if (R.isNil(index)) {
    return context;
  }

  const {record: origRecord, schema: origSchema, shared} = context;
  const [record, schema] = removeArrayItemFromSchema(fieldRef, index)(
    origRecord,
    origSchema,
  );
  const result = validatorExec(
    {
      record,
      origRecord,
      schema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared: R.mergeRight(R.defaultTo({}, shared), {fieldRef}),
    },
    validator,
  );
  const nextContext = context;
  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== result.validatedSchema) {
    nextContext.schema = result?.validatedSchema;
  }
  if (!R.isNil(context.props) && !R.isNil(context.props.apiError)) {
    nextContext.props.apiError = undefined;
  }
  return nextContext;
}
