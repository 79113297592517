import React, {ReactNode} from 'react';

import * as R from 'ramda';

import {
  DSL_SummaryContainer,
  DSL_SummaryFieldset,
  DSL_SummaryFieldsetHeader,
} from 'components/DesignSystem/Library';

import {DSP_SummaryItemRenderer, DSP_SummaryItemRendererProps} from '..';
import {DSP_SummaryTableRendererProps} from '../SummaryTableRenderer';

interface DSP_SummaryItemProps
  extends DSP_SummaryItemRendererProps,
    DSP_SummaryTableRendererProps {
  component?: string | ((props: DSP_SummaryItemRendererProps) => ReactNode);
}

export type DSP_SummaryItemConfig = string | DSP_SummaryItemProps;

type DSP_SummaryGroup = {
  title?: ReactNode;
  fields: DSP_SummaryItemConfig[];
};

type DSP_SummaryConfig = DSP_SummaryGroup[];

export type DSP_SummaryRendererProps = {
  summary: DSP_SummaryConfig;
};

export const DSP_SummaryRenderer = ({summary}: DSP_SummaryRendererProps) => {
  return (
    <DSL_SummaryContainer>
      {summary.map((summaryGroup, groupIndex) => (
        <DSL_SummaryFieldset key={groupIndex}>
          {summaryGroup.title && (
            <DSL_SummaryFieldsetHeader>
              {summaryGroup.title}
            </DSL_SummaryFieldsetHeader>
          )}
          {summaryGroup.fields.map((summaryField, fieldIndex) => {
            if (R.is(String, summaryField)) {
              return (
                <DSP_SummaryItemRenderer
                  key={summaryField}
                  fieldRef={summaryField}
                />
              );
            } else if (R.is(Object, summaryField)) {
              const {fieldRef, valueRenderer, ...rest} = summaryField;
              const DSP_SummaryItemComponent =
                summaryField.component || DSP_SummaryItemRenderer;
              return (
                <DSP_SummaryItemComponent
                  key={fieldIndex}
                  fieldRef={fieldRef}
                  valueRenderer={valueRenderer}
                  {...rest}
                />
              );
            }
          })}
        </DSL_SummaryFieldset>
      ))}
    </DSL_SummaryContainer>
  );
};

DSP_SummaryRenderer.displayName = 'DSP_SummaryRenderer';
