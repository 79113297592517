import React from 'react';

import {Menu as MuiMenu, MenuProps as MuiMenuProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../types';

export interface DSL_MenuProps
  extends Omit<MuiMenuProps, DSL_RestrictedVisualProps> {
  /** @deprecated This zIndex prop will be removed in the future, refer to https://nhvr.atlassian.net/browse/NHVRREP-29486 */
  zIndex?: React.CSSProperties['zIndex'];
}

export const DSL_Menu = ({...props}: DSL_MenuProps) => {
  return (
    <MuiMenu
      sx={{
        '& .MuiMenu-paper': {
          minHeight: theme => theme.spacing(6.5), // fixes an issue where the menu is too small
        },
      }}
      {...props}
    ></MuiMenu>
  );
};

DSL_Menu.displayName = 'DSL_Menu';

export {
  //
  /** @deprecated use DSL_MenuProps instead*/
  DSL_MenuProps as MenuProps, //
  /** @deprecated use DSL_Menu instead*/
  DSL_Menu as Menu,
};
