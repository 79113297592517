import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/assets/:assetDetailId/vcbTestVehicle/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAssetDetailAratTestVehicle" */ './ManageAssetDetailAratTestVehicle'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage test vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailAratTestVehicleWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'partnerSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Assets',
      to: '../../..',
    },
    {
      content: 'Asset',
      to: '../..',
      state: {'Manage asset': 'Test Vehicles'},
    },
  ],
};
