import React from 'react';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {useThemeSelection} from './ThemeSelectionProvider';
import {GlobalStyles} from './GlobalStyles';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {
  const {selectedTheme, availableThemes} = useThemeSelection();
  if (!selectedTheme) {
    /* eslint-disable no-console */
    console.error(
      'the Theme component must be within the scope of ThemeSelectionProvider',
    );
  }
  const theme = availableThemes[selectedTheme];

  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />
      {children}
    </MuiThemeProvider>
  );
};
