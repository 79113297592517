import React from 'react';

import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material';
import {DSL_CloseIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/CloseIconButton';
import {DSL_ErrorIcon} from 'components/DesignSystem/Library/Icons';
import {DSL_Heading5} from 'components/DesignSystem/Library/Typography/Heading5';
import {DSL_Heading6} from 'components/DesignSystem/Library/Typography/Heading6';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_FlexRow} from '../../FlexRow';

export interface DSL_DialogTitleProps
  extends Omit<MuiDialogTitleProps, DSL_RestrictedVisualProps | 'title'> {
  onClose?: () => void;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  hasErrorIcon?: boolean;
}

export const DSL_DialogTitle = ({
  title,
  subTitle,
  onClose,
  hasErrorIcon,
  ...props
}: DSL_DialogTitleProps) => {
  return (
    <MuiDialogTitle {...props}>
      <DSL_FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <DSL_FlexRow alignItems={'center'}>
          {hasErrorIcon && <DSL_ErrorIcon color={'error'} fontSize={'large'} />}
          <DSL_Heading5>{title}</DSL_Heading5>
        </DSL_FlexRow>
        {onClose && <DSL_CloseIconButton onClick={onClose} />}
      </DSL_FlexRow>
      <DSL_FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <DSL_Heading6 dangerouslySetInnerHTML={{__html: subTitle as string}} />
      </DSL_FlexRow>
    </MuiDialogTitle>
  );
};

DSL_DialogTitle.displayName = 'DSL_DialogTitle';

export {
  //
  /** @deprecated use DSL_DialogTitleProps instead*/
  DSL_DialogTitleProps as DialogTitleProps, //
  /** @deprecated use DSL_DialogTitle instead*/
  DSL_DialogTitle as DialogTitle,
};
