import React from 'react';

import {DSL_ArrowBackIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_BackIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_BackIconButton = (
  props: DSL_BackIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="back" {...props}>
      <DSL_ArrowBackIcon />
    </DSL_BaseIconButton>
  );
};

DSL_BackIconButton.displayName = 'DSL_BackIconButton';

export {
  //
  /** @deprecated use DSL_BackIconButtonProps instead*/
  DSL_BackIconButtonProps as BackIconButtonProps, //
  /** @deprecated use DSL_BackIconButton instead*/
  DSL_BackIconButton as BackIconButton,
};
