/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from 'ramda';
import {State} from 'xstate';

/**
 * Get the first state meta data object from the state machine state-context
 * @param state
 */
export default function getStateMetaHead(
  state: State<any, any, any, any>,
): any {
  if (R.isNil(state.meta)) {
    return {};
  }
  const metaKeys = R.keys(state.meta);
  if (metaKeys.length === 0) {
    return {};
  }
  return state.meta[R.keys(state.meta)[0]];
}
