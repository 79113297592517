import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Compliant = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 3.818h-4.18C14.4 2.764 13.3 2 12 2c-1.3 0-2.4.764-2.82 1.818H5c-1.1 0-2 .818-2 1.818v14.546c0 1 .9 1.818 2 1.818h14c1.1 0 2-.818 2-1.818V5.636c0-1-.9-1.818-2-1.818zm-7 0c.55 0 1 .41 1 .91s-.45.908-1 .908-1-.409-1-.909.45-.909 1-.909zm7 16.364H5V5.636h2v2.728h10V5.636h2v14.546z"
            fill="#000"
            fillOpacity={0.54}
          />
          <path
            d="M9.814 16.1l-2.845-2.798-.969.946L9.814 18 18 9.946 17.038 9l-7.224 7.1z"
            fill="#000"
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Compliant);
