import {BuiltInRoles} from '@regulatory-platform/common-utils/dist';
import UnauthenticatedLayout from 'components/layout/UnauthenticatedLayout';
import Loading from 'components/templates/content/Loading';
import Login from 'containers/Login';
import React, {FC, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import container from 'containers/index';
import mapsContainer from 'containers-map/index';
import {DSP_ErrorBoundary} from 'components/DesignSystem/Portal';
import {RouteElement} from './RouteElement';
import ContainerRouter from './ContainerRouter';

type UnauthenticatedRouterProps = {
  iframeMode: boolean;
};
const UnauthenticatedRouter: FC<UnauthenticatedRouterProps> = ({
  iframeMode,
}: UnauthenticatedRouterProps) => {
  return (
    <UnauthenticatedLayout iframeMode={iframeMode}>
      <DSP_ErrorBoundary>
        <Suspense fallback={<Loading unauthenticated={true} />}>
          <Routes>
            <Route
              path={mapsContainer.path + '/*'}
              element={<ContainerRouter containerConfig={mapsContainer} />}
            />
            {container.routes
              .filter(
                route =>
                  route.authorisation?.policies[0] === BuiltInRoles.EVERYONE,
              )
              .map((route, i) => (
                <Route
                  key={i}
                  path={route.path}
                  element={<RouteElement {...route} />}
                />
              ))}
            <Route path="/*" element={<RouteElement {...Login} />} />
          </Routes>
        </Suspense>
      </DSP_ErrorBoundary>
    </UnauthenticatedLayout>
  );
};

export default UnauthenticatedRouter;
