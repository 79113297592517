import React, {ReactNode} from 'react';

import {DSL_CloseIconButton} from '../../../Buttons/IconButtons/CloseIconButton';
import {DSL_FlexColumn} from '../../../Layout/FlexColumn';
import {DSL_FlexRow} from '../../../Layout/FlexRow';
import {DSL_Body1} from '../../../Typography/Body1';
import {DSL_Heading5} from '../../../Typography/Heading5';
import {DSL_BaseDrawerHeader} from '../BaseDrawerHeader/BaseDrawerHeader';

export interface DSL_StatusDrawerHeaderVariantProps {
  title: ReactNode;
  description?: ReactNode;
  padding?: 'SM' | 'MD' | 'LG';
  statuses?: ReactNode[];
  onClose?: () => void;
  isCloseDisabled?: boolean;
}

export const DSL_StatusDrawerHeaderVariant = ({
  title,
  padding,
  statuses,
  description,
  onClose,
}: DSL_StatusDrawerHeaderVariantProps) => {
  return (
    <DSL_BaseDrawerHeader padding={padding}>
      <DSL_FlexColumn gap={1}>
        <DSL_FlexRow justifyContent={'space-between'} gap={1}>
          <DSL_FlexRow
            data-name={'statuses-wrapper'}
            alignItems={'center'}
            gap={1}
          >
            {statuses}
          </DSL_FlexRow>
          <DSL_FlexRow alignItems={'center'} gap={1}>
            {onClose && (
              <DSL_CloseIconButton title={'Close'} onClick={onClose} />
            )}
          </DSL_FlexRow>
        </DSL_FlexRow>
        <DSL_FlexColumn gap={1}>
          <DSL_Heading5>{title}</DSL_Heading5>
          {description && (
            <DSL_Body1 color={'text.secondary'}>{description}</DSL_Body1>
          )}
        </DSL_FlexColumn>
      </DSL_FlexColumn>
    </DSL_BaseDrawerHeader>
  );
};

DSL_StatusDrawerHeaderVariant.displayName = 'DSL_StatusDrawerHeaderVariant';
