import {useMemo} from 'react';

import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import {TabStep} from 'components/navigation/types';

import {useDialog} from 'components/DesignSystem/Library';

import useLegacyWizards from '../../../../navigation/LegacyWizard/useWizards.Legacy';

export const useWizard = (tabSteps: TabStep[]) => {
  const [dialogOpen, openDialog, closeDialog] = useDialog();
  const {service} = useFormContext(); // TODO: move this into useWizards when all wizards are converted

  const [
    tabConfig,
    {onStepChange, handleBack, handleNext},
    {isStepFailed, isStepComplete, isStepSkipped},
  ] = useLegacyWizards(tabSteps, {service});

  const finalTabSteps = tabSteps.map((tabStep, index) => ({
    ...tabStep,
    error: isStepFailed(index),
    completed: isStepComplete(index),
    skipped: isStepSkipped(index),
  }));

  const activeStep = useMemo(
    () => tabConfig.activeStep,
    [tabConfig.activeStep],
  );

  return {
    activeStep,
    onStepChange,
    handleBack,
    handleNext,
    tabSteps: finalTabSteps,
    dialogOpen,
    openDialog,
    closeDialog,
  };
};
