import {TabStep} from 'components/navigation/types';

import {DSP_FormPageContainerLayoutProps} from '../../FormPageContainer';
import {useWizard} from '../useWizard';

import {DSP_WizardLayoutViewProps} from './WizardLayout.View';

export interface DSP_UseWizardLayoutArgs
  extends Omit<DSP_FormPageContainerLayoutProps, 'service'> {
  submitConfirmMessage?: string;
  tabSteps: TabStep[];
  recordId?: string;
  orientation?: DSP_WizardLayoutViewProps['orientation'];
  isCancelButtonVisible?: boolean;
}
export const useWizardLayout = ({
  submitConfirmMessage,
  tabSteps,
  recordId,
  orientation,
  ...props
}: DSP_UseWizardLayoutArgs): DSP_WizardLayoutViewProps => {
  const wizardProps = useWizard(tabSteps);

  return {
    orientation: orientation || 'horizontal',
    submitConfirmMessage,
    ...wizardProps,
    ...props,
  };
};
