import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/:accreditationId/vehicleDefects',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleDefectsAccreditation" */ './VehicleDefects'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Vehicle Defects',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'VehicleDefectAccreditationWithRelations.findById',
    policies: [
      {role: 'customerAccreditationRead'},
      {role: 'operationsAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation',
      to: '/accreditation',
    },
  ],
};
