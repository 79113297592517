import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export const AccreditationApplicationOrder = {
  path: '/:accreditationId/orders/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationApplicationOrder" */ './AccreditationApplicationOrder'
      ),
  ),
  visibleInMenu: false,
  menuTitle: 'Order Application',
  hideInProduction: true,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.updateById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
