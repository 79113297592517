import AuthenticatedLayout from 'components/layout/AuthenticatedLayout';
import Loading from 'components/templates/content/Loading';
import React, {FC, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import mapContainer from 'containers-map/index';
import VehicleNetworkMap from 'containers-map/VehicleNetworkMap';
import {DSP_ErrorBoundary} from 'components/DesignSystem/Portal';
import {RouteElement} from './RouteElement';
import {AuthStoreState} from 'utils/machines/authMachine';
import {UserProfile} from '@regulatory-platform/common-utils';
import logoutRoute from 'containers/Logout';
import Login from 'containers/Login';
import ResetPassword from 'containers/ResetPassword';
import Status from 'containers/Status';
import Roadmap from 'containers-map/Roadmap';

type MapRouterProps = {
  authState: AuthStoreState;
  iframeMode: boolean;
};

const MapRouter: FC<MapRouterProps> = ({authState, iframeMode}) => {
  const userProfile = authState.context.userProfile as UserProfile;

  const path = window.location.pathname;
  const isUnauthenticatedMapUrl =
    !authState.matches('authenticated') && path.match(mapContainer.path);
  const parentDirectory = isUnauthenticatedMapUrl ? mapContainer.path : '';
  return (
    <AuthenticatedLayout
      map={true}
      iframeMode={iframeMode}
      userProfile={userProfile}
    >
      <DSP_ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Routes>
            {mapContainer.routes.map((route, i) => (
              <Route
                key={i}
                path={parentDirectory + route.path}
                element={<RouteElement {...route} />}
              />
            ))}
            <Route
              key={'logout'}
              path={logoutRoute.path}
              element={<RouteElement {...logoutRoute} />}
            />
            <Route
              key={'login'}
              path={Login.path}
              element={<RouteElement {...Login} />}
            />
            <Route
              key={'status'}
              path={Status.path}
              element={<RouteElement {...Status} />}
            />
            <Route
              key={'roadmap'}
              path={Roadmap.path}
              element={<RouteElement {...Roadmap} />}
            />
            <Route
              key={'reset-password'}
              path={ResetPassword.path}
              element={<RouteElement {...ResetPassword} />}
            />
            {/**
             * Catch all
             */}
            <Route
              path="/*"
              element={<RouteElement {...VehicleNetworkMap} />}
            />
          </Routes>
        </Suspense>
      </DSP_ErrorBoundary>
    </AuthenticatedLayout>
  );
};

export default MapRouter;
