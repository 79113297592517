import Today from '@mui/icons-material/Today';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/roadmap',
  component: lazy(() => import(/* webpackChunkName: "Roadmap" */ './Roadmap')),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Roadmap',
  menuIcon: Today,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
