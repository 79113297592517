import React, {useState} from 'react';

import {
  TextField,
  Autocomplete,
  InputAdornment,
  AutocompleteProps,
  CircularProgress,
} from '@mui/material';
import * as R from 'ramda';

import {DSL_RestrictedVisualProps} from '../../../../types';
import {DSL_FormControl} from '../../FormControl';

type DSP_TextFieldProps = React.ComponentProps<typeof TextField>;

export interface DSL_BaseAutocompleteInputProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
      AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
      DSL_RestrictedVisualProps | 'defaultValue' | 'fullWidth' | 'renderInput'
    >,
    Pick<DSP_TextFieldProps, 'label' | 'helperText' | 'placeholder' | 'error'> {
  textFieldStartAdornment?: React.ReactNode;
}

export const DSL_BaseAutocompleteInput = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  label,
  helperText,
  placeholder,
  error,
  loading,
  textFieldStartAdornment,
  size = 'medium',
  filterOptions = options => options,
  ...props
}: DSL_BaseAutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [hasFocus, setHasFocus] = useState(false);

  // TODO: For some reason the auto label shrinking doesn't work.  This appears to be isolated to our codebase as I can
  //  easily get it working in minimal codesandbox reproductions.
  const hasValue = R.is(Array, props.value)
    ? props.value.length > 0
    : !!props.value;

  return (
    <DSL_FormControl>
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
        {...props}
        loadingText={'Searching...'}
        size={size}
        filterOptions={filterOptions}
        noOptionsText={loading ? 'Loading...' : 'No match found'}
        onFocus={e => {
          setHasFocus(true);
          props.onFocus?.(e);
        }}
        onBlur={e => {
          setHasFocus(false);
          props.onBlur?.(e);
        }}
        renderInput={({InputProps, ...params}) => {
          return (
            <TextField
              label={label}
              helperText={helperText}
              placeholder={placeholder}
              error={error}
              variant={'outlined'}
              sx={{
                '& .MuiAutocomplete-endAdornment': {
                  // this ensures that the dropdown arrow doesn't have text underneath it
                  // TODO: Fix the dark mode which requires backgroundImage(linear-gradient(rgba(255, 255, 255, 0.05)) to match the color of the input but needs to not break other themes
                  bgcolor: 'background.paper',
                },
              }}
              InputProps={{
                ...InputProps,
                startAdornment: textFieldStartAdornment ? (
                  <InputAdornment position={'start'}>
                    {textFieldStartAdornment}
                    {InputProps.startAdornment}
                  </InputAdornment>
                ) : (
                  InputProps.startAdornment
                ),
                endAdornment: loading ? (
                  <InputAdornment
                    position={'end'}
                    sx={{right: '12px', position: 'absolute'}}
                  >
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : (
                  InputProps.endAdornment
                ),
              }}
              {...params}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: hasFocus || !!(hasValue || props.inputValue),
              }}
              size={size}
            />
          );
        }}
      />
    </DSL_FormControl>
  );
};

DSL_BaseAutocompleteInput.displayName = 'DSL_BaseAutocompleteInput';

export {
  //
  /** @deprecated use DSL_BaseAutocompleteInputProps instead*/
  DSL_BaseAutocompleteInputProps as BaseAutocompleteInputProps, //
  /** @deprecated use DSL_BaseAutocompleteInput instead*/
  DSL_BaseAutocompleteInput as BaseAutocompleteInput,
};
