import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export const AccreditationApplication = {
  path: '/:accreditationId/applications/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationApplication" */ './AccreditationApplication'
      ),
  ),
  visibleInMenu: false,
  menuTitle: 'Update Application',
  hideInProduction: true,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.updateById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
