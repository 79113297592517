import {useMemo} from 'react';

import {DSL_BreadcrumbProps} from 'components/DesignSystem/Library';

import {useCurrentRouteConfig} from '../../hooks/useCurrentRouteConfig';

export const useAuthenticatedPageHeader = (
  overrideBreadcrumbs?: DSL_BreadcrumbProps[],
  activeBreadcrumbLabel?: string,
) => {
  const route = useCurrentRouteConfig();

  const breadcrumbs: DSL_BreadcrumbProps[] = useMemo(
    () =>
      route?.sections?.map(section => {
        const href: string = section.to as string;
        return {
          href,
          state: section.state,
          label: section.content,
          active: false,
        };
      }) ?? [],
    [route],
  );

  return {
    breadcrumbs: [
      ...(overrideBreadcrumbs ?? breadcrumbs),
      {
        href: './',
        label: activeBreadcrumbLabel ?? route?.menuTitle,
        active: true,
      },
    ],
  };
};
