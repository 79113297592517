import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Assets from 'containers-spatial/Assets';
import RoadManagement from 'containers-spatial/RoadManagement';
import ManageAsset from 'containers-spatial/Assets/ManageAsset';
import ManageAssetDetailAratRefVehicle from 'containers-spatial/Assets/ManageAssetDetailAratRefVehicle';
import ManageAssetDetailAratTestVehicle from 'containers-spatial/Assets/ManageAssetDetailAratTestVehicle';
import NewAssetDetailAratRefVehicleGateway from 'containers-spatial/Assets/NewAssetDetailAratRefVehicleGateway';
import NewAssetDetailAratTestVehicleGateway from 'containers-spatial/Assets/NewAssetDetailAratTestVehicleGateway';
import NewAssetGateway from 'containers-spatial/Assets/NewAssetGateway';
import AssetPeakReferenceVehicle from 'containers-spatial/Assets/AssetPeakReferenceVehicle';
import AssetPeakHistoricalVehicle from 'containers-spatial/Assets/AssetPeakHistoricalVehicle';
import AratRefVehicles from 'containers-spatial/AratRefVehicle';
import ManageAratRefVehicle from '../containers-spatial/AratRefVehicle/ManageAratRefVehicle';
import NewAratRefVehicleGateway from '../containers-spatial/AratRefVehicle/NewAratRefVehicleGateway';

export default {
  routes: [
    RoadManagement,
    Assets,
    ManageAsset,
    NewAssetDetailAratRefVehicleGateway,
    ManageAssetDetailAratRefVehicle,
    NewAssetDetailAratTestVehicleGateway,
    ManageAssetDetailAratTestVehicle,
    NewAssetGateway,
    AratRefVehicles,
    AssetPeakReferenceVehicle,
    AssetPeakHistoricalVehicle,
    ManageAratRefVehicle,
    NewAratRefVehicleGateway,
  ],
  path: '/access',
  menuTitle: 'Roads and assets',
  menuDescription: 'Manage heavy vehicle public road infrastructure',
  showChildRoutesInMenu: true,
  menuIcon: LocationOnOutlined,
};
