import React from 'react';

import {DSL_BaseDrawer, DSL_BaseDrawerProps} from '../../BaseDrawer';

import {
  useMinimizeableNonModalDrawer,
  DSL_UseMinimizeableNonModalDrawerArgs,
} from './useMinimizeableNonModalDrawer';

export interface DSL_MinimizeableNonModalDrawerProps
  extends Pick<DSL_BaseDrawerProps, 'onClose' | 'children' | 'id' | 'isHidden'>,
    DSL_UseMinimizeableNonModalDrawerArgs {}

export const DSL_MinimizeableNonModalDrawer = ({
  children,
  onClose,
  id,
  isHidden,
  ...args
}: DSL_MinimizeableNonModalDrawerProps) => {
  const {isMinimized, ...viewProps} = useMinimizeableNonModalDrawer(args);

  return (
    <DSL_BaseDrawer
      aria-hidden={!isMinimized}
      data-name={'non-modal-drawer'}
      isHidden={isHidden}
      id={id}
      {...viewProps}
    >
      {children}
    </DSL_BaseDrawer>
  );
};

DSL_MinimizeableNonModalDrawer.displayName = 'DSL_MinimizeableNonModalDrawer';

export {
  //
  /** @deprecated use DSL_MinimizeableNonModalDrawerProps instead*/
  DSL_MinimizeableNonModalDrawerProps as MinimizeableNonModalDrawerProps, //
  /** @deprecated use DSL_MinimizeableNonModalDrawer instead*/
  DSL_MinimizeableNonModalDrawer as MinimizeableNonModalDrawer,
};
