import React, {ForwardedRef, forwardRef} from 'react';

import {
  DSL_BaseCardActionArea,
  DSL_BaseCardActionAreaProps,
} from '../BaseCardActionArea';

export interface DSL_ButtonCardActionAreaProps
  extends Omit<DSL_BaseCardActionAreaProps, 'LinkComponent' | 'href'> {
  onClick: NonNullable<DSL_BaseCardActionAreaProps['onClick']>;
  disableRipple?: boolean;
}

export const DSL_ButtonCardActionArea = forwardRef(
  (
    props: DSL_ButtonCardActionAreaProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return <DSL_BaseCardActionArea ref={ref} {...props} />;
  },
);

DSL_ButtonCardActionArea.displayName = 'DSL_ButtonCardActionArea';

export {
  //
  /** @deprecated use DSL_ButtonCardActionAreaProps instead*/
  DSL_ButtonCardActionAreaProps as ButtonCardActionAreaProps, //
  /** @deprecated use DSL_ButtonCardActionArea instead*/
  DSL_ButtonCardActionArea as ButtonCardActionArea,
};
