import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      '0.5': {
        width: '5ch',
      },
      1: {
        width: '10ch',
      },
      2: {
        width: '22ch',
      },
      3: {
        width: '34ch',
      },
      4: {
        width: '46ch',
      },
      5: {
        width: '58ch',
      },
      6: {
        width: '70ch',
      },
      12: {
        width: '90ch',
      },
      16: {
        width: '100%',
        height: '10vh',
      },
      100: {
        width: '100%',
      },
      selectBox: {
        '& .MuiSelect-select': {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
      menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      label: {
        transform: 'translate(14px, 12px) scale(1)',
        '&.MuiFormLabel-filled': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
      },
      textCounter: {
        textAlign: 'end',
      },
      fieldRow: {
        marginLeft: theme.spacing(-1),
        marginBottom: theme.spacing(1),
      },
      tableFieldDisabled: {
        backgroundColor: theme.palette.background.paper,
      },
      selectControlMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      autocompleteControl: {
        margin: theme.spacing(1),
      },
      margin: {
        margin: theme.spacing(1),
      },
      marginTopBottom: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      buttonStack: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        float: 'left',
        '@media print': {
          display: 'none',
        },
      },
      buttonStackRight: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        float: 'right',
        '@media print': {
          display: 'none',
        },
      },
      buttonStackFlex: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        justifyContent: 'flex-start',
      },
      button: {
        minWidth: '10vh',
      },
      headerIconButton: {
        marginTop: theme.spacing(-0.5),
      },
      formIconButton: {
        marginTop: theme.spacing(1.5),
      },
      formMargin: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
      },
      rightAlign: {
        float: 'right',
        marginRight: theme.spacing(2),
      },
      icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
      },
      addressSearch: {
        width: '100%',
        marginTop: theme.spacing(-1),
        marginBottom: '10px',
      },
      renderImage: {
        marginRight: '15px',
        marginTop: '-9px',
      },
      tableAutoWidth: {
        width: 'auto',
      },
      forThumbnail: {
        paddingLeft: '0px',
      },
      printOptions: {
        '@media print': {
          maxWidth: '33.3%',
          flexBasis: '33.3%',
        },
      },
    }),
  {index: 1},
);
