/* eslint-disable @typescript-eslint/explicit-function-return-type */
import getQueryParams from 'app/router/utils/getQueryParams';
import {from} from 'rxjs';
import * as R from 'ramda';
import appMachine, {AppObservable, AppService} from 'utils/machines/appMachine';
import {interpret} from 'xstate';

const windowLocation = window.location;
const initialQueryParams = getQueryParams(windowLocation.search);

const machine = appMachine
  .withConfig({
    guards: {
      isThirdPartyVerification: (context): boolean => {
        return context.type === 'loginWithToken';
      },
      isIframe: (context): boolean => {
        return context.mode === 'iframe' && context.type !== 'loginWithToken';
      },
      isBeta: (context): boolean => {
        return context.mode === 'beta';
      },
      isB2CLogin: (): boolean => {
        return (
          window.location.pathname === '/b2cLogin' &&
          !R.isNil(window.location.hash) &&
          R.startsWith('#id_token=', window.location.hash)
        );
      },
      isB2CLoginError: (): boolean => {
        return (
          windowLocation.pathname === '/b2cLogin' &&
          !R.isNil(windowLocation.hash) &&
          !R.startsWith('#id_token=', windowLocation.hash)
        );
      },
      isMap: (context): boolean => {
        return context.hostname.indexOf('maps') > -1;
      },
    },
  })
  .withContext({
    mode: initialQueryParams.mode,
    type: initialQueryParams.type,
    pathname: window.location.pathname,
    hostname: window.location.hostname,
  });

const service = interpret(machine) as AppService;

const state$ = from(service as never) as AppObservable;

export default {state$, service};
