import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_PNA = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 22H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8l6 6v3h-2V9h-5V4H6v16h7v2z"
            fill="#000"
            fillOpacity={0.54}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 20.856l-1.535-.39-.738 1.451-1.311-2.81-1.312 2.81-.738-1.45-1.534.39 1.558-3.339-.135-.06c-.197-.088-.396-.175-.515-.339-.117-.161-.14-.376-.162-.59a2 2 0 00-.07-.409c-.039-.12-.108-.237-.177-.356-.11-.188-.222-.378-.222-.589 0-.21.112-.4.222-.588.07-.119.138-.236.177-.356a2 2 0 00.07-.409c.023-.215.045-.429.162-.59.12-.164.318-.252.515-.339.13-.057.258-.113.364-.19.105-.077.197-.18.29-.284.144-.163.29-.326.485-.39.186-.06.393-.016.604.03.14.03.28.06.418.06.137 0 .278-.03.418-.06.21-.046.418-.09.604-.03.195.064.34.227.485.39.093.104.185.207.29.283.106.078.234.134.363.19.198.088.396.176.515.34.118.161.14.375.162.59a2 2 0 00.07.409c.04.12.108.237.178.356.11.188.222.378.222.588 0 .21-.112.401-.222.589-.07.119-.139.236-.178.356a2 2 0 00-.07.408c-.022.215-.044.43-.162.591-.119.164-.317.251-.515.338l-.134.061L21 20.856zm-5.606-5.68a2.021 2.021 0 104.043 0 2.021 2.021 0 00-4.043 0z"
            fill="#000"
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_PNA);
