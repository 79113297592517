import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Vehicle = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 5a2 2 0 012 2h1.281a1 1 0 01.904.572l1.041 2.197H21a1 1 0 011 1V14a1 1 0 01-1 1h-2.05a2.5 2.5 0 11-4.9 0h-5.1a2.5 2.5 0 11-4.9 0H2V7a2 2 0 012-2h10zM6.5 15a.5.5 0 100 1 .5.5 0 000-1zm6.5-2V7H3.714v6H13zm3.5 2a.5.5 0 100 1 .5.5 0 000-1zm4-2v-2h-2.315L17 8.5h-2V13h5.5z"
            fill={'#000'}
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Vehicle);
