import React, {useCallback, useEffect, useRef} from 'react';

import {useSizeObserver} from '../../hooks/useSizeObserver';

import {DSL_BoxProps, DSL_Box} from './Box';

export interface DSL_HeightObservingBoxProps extends DSL_BoxProps {
  onHeightChange: (height: number) => void;
}

export const DSL_HeightObservingBox = ({
  onHeightChange,
  ...props
}: DSL_HeightObservingBoxProps) => {
  const boxRef = useRef<HTMLElement>(null);

  useSizeObserver({
    ref: boxRef,
    onResize: useCallback(
      entries => {
        const resizeHeight = Math.round(entries[0].contentRect.height);
        onHeightChange(resizeHeight);
      },
      [onHeightChange],
    ),
  });

  useEffect(() => {
    return () => {
      onHeightChange(0);
    };
  }, [onHeightChange]);

  return <DSL_Box ref={boxRef} {...props} />;
};
