import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';
import {ConsentWorkflowState} from 'containers-access/AccessProductApplication/types';

import {DSP_NotificationRendererComponent} from '../../types';

import {DSP_AccessProductChangeSetOperationsNotificationCard} from './Operations/AccessProductChangeSetOperationsNotificationCard.Connector';
import {DSP_AccessProductChangeSetPartnerNotificationCard} from './Partner/AccessProductChangeSetPartnerNotificationCard.Connector';
import {DSP_AccessProductChangeSetNotificationItem} from './types';

export const consentWorkflowStateDescriptionMap: {
  [DSP_ConsentState in ConsentWorkflowState]?: string | undefined;
} = {
  [ConsentWorkflowState.REVIEWING_STATE]: 'started reviewing the update',
  [ConsentWorkflowState.AWAITING_PUBLISH_STATE]: 'approved the update request',
  [ConsentWorkflowState.SUBMITTED_STATE]: 'created a new update request',
  [ConsentWorkflowState.REFERRED_STATE]: 'referred the update request',
  [ConsentWorkflowState.PUBLISHED_STATE]: 'Network update request is published',
  [ConsentWorkflowState.CLOSED_STATE]: 'Network update request is closed',
};

export const accessProductChangeSetAccountTypeNotificationRendererMap: {
  [DSP_Type in AuthorisationAccountTypes]?:
    | DSP_NotificationRendererComponent<DSP_AccessProductChangeSetNotificationItem>
    | undefined;
} = {
  [AuthorisationAccountTypes.PARTNER]:
    DSP_AccessProductChangeSetPartnerNotificationCard,
  [AuthorisationAccountTypes.OPERATIONS]:
    DSP_AccessProductChangeSetOperationsNotificationCard,
};
