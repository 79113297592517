import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Fatigue = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 4.3c0-.166.13-.3.29-.3h2.993a.29.29 0 01.262.173.309.309 0 01-.04.32L14.91 7.7h2.373c.16 0 .29.134.29.3 0 .166-.13.3-.29.3H14.29a.29.29 0 01-.263-.173.309.309 0 01.04-.32L16.663 4.6H14.29a.295.295 0 01-.29-.3zM18.2 6.8c0-.166.13-.3.29-.3h2.22a.29.29 0 01.263.173.309.309 0 01-.04.32L19.11 9.25h1.6c.16 0 .29.134.29.3 0 .166-.13.3-.29.3h-2.22a.29.29 0 01-.263-.173.309.309 0 01.04-.32L20.09 7.1h-1.6a.295.295 0 01-.29-.3zM15.159 9.55c0-.166.13-.3.29-.3h1.737a.29.29 0 01.263.173.309.309 0 01-.04.32l-1.34 1.657h1.117c.16 0 .29.134.29.3 0 .166-.13.3-.29.3h-1.738a.29.29 0 01-.262-.173.309.309 0 01.04-.32l1.34-1.657h-1.118a.295.295 0 01-.29-.3z"
            fill="#000"
            fillOpacity={0.54}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 13.5a3.25 3.25 0 100-6.5 3.25 3.25 0 100 6.5zm0-4.875a1.63 1.63 0 011.625 1.625 1.63 1.63 0 01-1.625 1.625 1.63 1.63 0 01-1.625-1.625A1.63 1.63 0 0110.5 8.625zM4 17.563c0-2.162 4.33-3.25 6.5-3.25S17 15.4 17 17.563V20H4v-2.438zm1.625.008v.804h9.75v-.813c-.162-.576-2.681-1.625-4.875-1.625s-4.713 1.049-4.875 1.634z"
            fill="#000"
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Fatigue);
