import {ApolloQueryResult, FetchResult, useApolloClient} from '@apollo/client';
import {useInterpret} from '@xstate/react';
import {assign} from 'xstate';
import notificationsMachine, {
  NotificationFilter,
  NotificationItem,
  NotificationsEventObject,
  NotificationsMachineContext,
  NotificationType,
  RawNotificationItem,
} from 'utils/machines/notificationsMachine';
import {NotificationsFindDocument} from './queries/NotificationsFind.generated';
import {Notification, NotificationUserViews} from 'typeDefs/types';
import {add, parseISO} from 'date-fns';
import {useUserProfile} from 'app/services/useUserProfile';
import {NotificationUserViewCreateDocument} from './mutations/NotificationUserViewCreate.generated';
import {NotificationUserViewCreateAllDocument} from './mutations/NotificationUserViewCreateAll.generated';
import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';

export default function useNotificationsMachine() {
  const client = useApolloClient();

  const userProfile = useUserProfile();

  return useInterpret<NotificationsMachineContext, NotificationsEventObject>(
    notificationsMachine,
    {
      context: {
        notifications: [],
        filteredNotifications: [],
        filterBy: NotificationFilter.AllNotifications,
        // NOTE: We don't yet support paging in the UI, even though the server supports it
        // So for now, we'll just use a sensible limit
        paginationLimit: 1000,
        paginationPage: 0,
      },
      guards: {},
      actions: {
        setNotifications: assign((context, event) => {
          const rawNotifications = event.data?.data
            ?.notificationsFind as RawNotificationItem[];
          const notifications = rawNotifications.map(notification => ({
            ...notification,
            readByLoggedInUser: !!notification.userViews?.filter(
              nuv => nuv?.userId === userProfile?.id,
            ).length,
            accountType: notification.accountType as AuthorisationAccountTypes,
            notificationType: notification.notificationType as NotificationType,
            created: notification.created
              ? parseISO(notification.created)
              : undefined,
            modified: notification.modified
              ? parseISO(notification.modified)
              : undefined,
            viewedDate: notification.viewedDate
              ? parseISO(notification.viewedDate)
              : undefined,
          }));

          return {
            ...context,
            notifications,
            filteredNotifications: filterNotifications(
              context.filterBy,
              notifications,
            ),
          };
        }),
        clearNotifications: assign({
          notifications: () => {
            return [] as NotificationItem[];
          },
        }),
        setFilter: assign((context, event) => ({
          ...context,
          filterBy: event.filterBy,
          filteredNotifications: filterNotifications(
            event.filterBy,
            context.notifications,
          ),
        })),
      },
      services: {
        getNotifications: (
          context,
        ): Promise<ApolloQueryResult<Notification>> => {
          // Note: we use a hard-coded 'last 30 days' here, calculated now (so on every refresh) as opposed to in the state
          // This will suffice until there are further requirements for fetching more
          // i.e. In the future we may have state for a prevFetchedFrom bookmark, or pagination params etc
          const fetchFromDate = add(new Date(), {days: -30});
          return client.query({
            query: NotificationsFindDocument,
            variables: {
              notificationControllerFindFilter: {
                limit: context.paginationLimit,
                skip: context.paginationPage,
                where: {
                  created: {gt: fetchFromDate},
                },
                order: ['created DESC'],
              },
            },
            errorPolicy: 'none',
            // NOTE: We need to turn the apollo client cache off - as the query parameters don't change when the user
            // profile changes (the query is the same as it doesn't have the user or rmID as vars, as the server gets
            // this from the token
            fetchPolicy: 'no-cache',
          });
        },
        markAsRead: (_, event): Promise<FetchResult<NotificationUserViews>> => {
          const notificationId = event.markAsReadEvent?.notificationId;
          if (!notificationId) {
            throw new Error('Notification ID not provided');
          }

          return client.mutate({
            mutation: NotificationUserViewCreateDocument,
            variables: {
              notificationUserViewsInput: {
                notificationId: notificationId,
              },
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'none',
          });
        },
        markAllAsRead: (
          context,
        ): Promise<FetchResult<NotificationUserViews>> => {
          const unread = context.notifications.filter(
            n => !n.readByLoggedInUser,
          );
          const unreadIdsInObjectWrapper = unread.map(n => {
            return {
              notificationId: n.notificationId,
            };
          });

          return client.mutate({
            mutation: NotificationUserViewCreateAllDocument,
            variables: {
              notificationUserViewsCreateAllInput: unreadIdsInObjectWrapper,
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'none',
          });
        },
      },
    },
  );
}

function filterNotifications(
  filterBy: NotificationFilter | undefined,
  allNotifications: NotificationItem[],
): NotificationItem[] {
  if (filterBy === NotificationFilter.UnreadOnly) {
    return allNotifications.filter(
      ({readByLoggedInUser}) => !readByLoggedInUser,
    );
  }

  return allNotifications;
}
