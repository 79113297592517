import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/assets/create',
  component: lazy(
    () => import(/* webpackChunkName: "NewAssetGateway" */ './NewAssetGateway'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add new asset',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailWithRelations.create',
    policies: [
      {
        role: 'partnerAccessUser',
      },
      {
        role: 'partnerSpatialUser',
      },
      {
        role: 'operationsSpatialUser',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Assets',
      to: '..',
    },
  ],
};
