/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {ApolloClient} from '@apollo/client';
import queryLoaderMachine, {
  QueryLoaderMachineConfig,
} from 'utils/machines/queryLoaderMachine';
import {GetPbsApprovalVehicleMappingsDocument} from './queries/PbsApprovalVehicleMappingFindById.generated';
import {GetPbsApplicationPbsApprovalVehicleMappingsDocument} from './queries/PbsApplicationPbsApprovalVehicleMappings.generated';
import {GetVehicleComponentWithRelationsDocument} from './queries/VehicleComponentFindById.generated';
import {GetvehicleComponentsDocument} from './queries/VehicleComponentsFind.generated';
import {GetPbsVehicleUnitSetPbsApprovalVehicleMappingsDocument} from './queries/PbsVehicleUnitSetPbsApprovalVehicleMappings.generated';

export function pbsVehicleUnitSetPbsApprovalVehicleMappingsMachine(
  client: ApolloClient<object>,
  setId: number,
) {
  return queryLoaderMachine(
    {
      id: 'pbsVehicleUnitSetPbsApprovalVehicleMappings',
    } as unknown as QueryLoaderMachineConfig,
    {
      services: {
        apiLoad: () => {
          return client.query({
            query: GetPbsVehicleUnitSetPbsApprovalVehicleMappingsDocument,
            /**
             * id - filter by setId
             */
            variables: {id: setId},
            errorPolicy: 'none',
            fetchPolicy: 'cache-first',
          });
        },
      },
    },
  );
}

export function pbsApplicationPbsApprovalVehicleMappingsMachine(
  client: ApolloClient<object>,
  id: number,
  positionIndex: number,
) {
  return queryLoaderMachine(
    {
      id: 'pbsApplicationPbsApprovalVehicleMappings',
    } as unknown as QueryLoaderMachineConfig,
    {
      services: {
        apiLoad: () => {
          return client.query({
            query: GetPbsApplicationPbsApprovalVehicleMappingsDocument,
            variables: {id, positionIndex},
            errorPolicy: 'none',
            fetchPolicy: 'cache-first',
          });
        },
      },
    },
  );
}

export function pbsApprovalVehicleMappingMachine(
  client: ApolloClient<object>,
  id: number,
) {
  return queryLoaderMachine(
    {
      id: 'pbsApprovalVehicleMapping',
    } as unknown as QueryLoaderMachineConfig,
    {
      services: {
        apiLoad: () => {
          return client.query({
            query: GetPbsApprovalVehicleMappingsDocument,
            /**
             * id - find by pbsApprovalVehicleMappingId
             */
            variables: {id},
            errorPolicy: 'none',
            fetchPolicy: 'cache-first',
          });
        },
      },
    },
  );
}

export function vehicleComponentMachine(
  client: ApolloClient<object>,
  vehicleComponentId: number,
) {
  return queryLoaderMachine(
    {
      id: 'vehicleComponent',
    } as unknown as QueryLoaderMachineConfig,
    {
      services: {
        apiLoad: () => {
          return client.query({
            query: GetVehicleComponentWithRelationsDocument,
            /**
             * id - filter by vehicleComponentId
             */
            variables: {id: vehicleComponentId},
            errorPolicy: 'none',
            fetchPolicy: 'cache-first',
          });
        },
      },
    },
  );
}

export function vehicleComponentsMachine(
  client: ApolloClient<object>,
  vehicleComponentsIds: number[],
) {
  return queryLoaderMachine(
    {
      id: 'vehicleComponents',
    } as unknown as QueryLoaderMachineConfig,
    {
      services: {
        apiLoad: () => {
          return client.query({
            query: GetvehicleComponentsDocument,
            /**
             * id - filter by vehicleComponentIds
             */
            variables: {vehicleComponentsIds},
            errorPolicy: 'none',
            fetchPolicy: 'cache-first',
          });
        },
      },
    },
  );
}
