import React from 'react';

import {DSL_FlexRow, DSL_FlexRowProps} from '../../../Layout/FlexRow';

export type DSL_ButtonBarGroupProps = DSL_FlexRowProps;

export const DSL_ButtonBarGroup = ({
  gap = 2,
  alignItems = 'center',
  children,
}: DSL_ButtonBarGroupProps) => {
  return (
    <DSL_FlexRow gap={gap} alignItems={alignItems}>
      {children}
    </DSL_FlexRow>
  );
};

DSL_ButtonBarGroup.displayName = 'DSL_ButtonBarGroup';

export {
  //
  /** @deprecated use DSL_ButtonBarGroupProps instead*/
  DSL_ButtonBarGroupProps as ButtonBarGroupProps, //
  /** @deprecated use DSL_ButtonBarGroup instead*/
  DSL_ButtonBarGroup as ButtonBarGroup,
};
