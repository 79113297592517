import * as R from 'ramda';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormStoreContainer} from 'utils/stores/types';
import {TabStep} from '../types';

export interface LocationStateTab {
  [key: string]: string;
}
export type useTabsResults = [TabStep, (label: string) => void];

export default function useTabs(
  label: string,
  tabSteps: TabStep[],
  service?: FormStoreContainer['service'],
): useTabsResults {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedTab = R.defaultTo(
    tabSteps[0].label,
    (location.state as LocationStateTab)?.[label],
  );
  const activeStep = R.defaultTo(
    tabSteps[0],
    R.find(R.propEq('label', selectedTab), tabSteps),
  );
  const onStepChange = (nextTabLabel: string): void => {
    const nextStep = R.find(R.propEq('label', nextTabLabel), tabSteps);
    if (R.isNil(nextStep)) {
      return;
    }
    if (!R.isNil(service) && !R.isNil(activeStep.validateFields)) {
      const validateFields = R.defaultTo([], activeStep.validateFields);
      service.send({
        type: 'TAB_TOUCHED',
        fieldRef: activeStep.label,
        fieldRefs: validateFields,
      });
    }
    navigate(location.pathname + R.defaultTo('', location.search), {
      state: R.assoc(label, nextTabLabel, location.state),
    });
    if (!R.isNil(service) && nextStep.onClickBlurField) {
      service.send({
        type: 'BLUR',
        fieldRef: nextStep.onClickBlurField as string,
      });
    }
    if (!R.isNil(service) && nextStep.onClickValidateField) {
      service.send({
        type: 'VALIDATE',
        fieldRef: nextStep.onClickValidateField as string,
      });
    }
  };
  return [activeStep, onStepChange];
}
