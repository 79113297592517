import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_AdvancedFatigue = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 7.5v1h1v-1h1v-1h-1v-1h-1v1h-1v1h1z"
            fill="#000"
            fillOpacity={0.54}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 7c0-1.655-1.345-3-3-3s-3 1.345-3 3 1.345 3 3 3 3-1.345 3-3zm-5 0a2 2 0 113.999-.001A2 2 0 0115 7zM13.75 10.25a3.25 3.25 0 11-6.5 0 3.25 3.25 0 116.5 0zm-1.625 0A1.63 1.63 0 0010.5 8.625a1.63 1.63 0 00-1.625 1.625 1.63 1.63 0 001.625 1.625 1.63 1.63 0 001.625-1.625zM10.5 14.313c-2.17 0-6.5 1.088-6.5 3.25V20h13v-2.438c0-2.16-4.33-3.25-6.5-3.25zm-4.875 4.062v-.804c.162-.585 2.681-1.634 4.875-1.634s4.713 1.049 4.875 1.626v.812h-9.75z"
            fill="#000"
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_AdvancedFatigue);
