import {UserProfile} from '@regulatory-platform/common-utils';
import React from 'react';

import {AppBarWithDrawerOffsetObserver} from '../../navigation';
import {
  DSL_DrawerProvider,
  DSL_FullHeightColumnPageLayout,
  DSL_FullHeightColumnPageLayoutHeader,
  DSL_FullHeightColumnPageLayoutMain,
} from 'components/DesignSystem/Library';

type AuthenticatedLayoutProps = {
  userProfile?: UserProfile;
  children?: React.ReactChildren | React.ReactElement | React.ReactNode;
  iframeMode: boolean;
  map?: boolean;
};

const mainId = 'page-layout-main';
const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  userProfile,
  children,
  iframeMode,
  map,
}: AuthenticatedLayoutProps) => {
  return (
    <DSL_DrawerProvider>
      <DSL_FullHeightColumnPageLayout
        printMinWidth={iframeMode ? undefined : '768px'}
        printHeight={'auto'}
      >
        <DSL_FullHeightColumnPageLayoutHeader>
          {!iframeMode && (
            <AppBarWithDrawerOffsetObserver
              userProfile={userProfile}
              map={map}
            />
          )}
        </DSL_FullHeightColumnPageLayoutHeader>
        <DSL_FullHeightColumnPageLayoutMain id={mainId}>
          {children}
        </DSL_FullHeightColumnPageLayoutMain>
      </DSL_FullHeightColumnPageLayout>
    </DSL_DrawerProvider>
  );
};

export function scrollMainLayoutToTop() {
  document.getElementById(mainId)?.scrollTo(0, 0);
}

export default AuthenticatedLayout;
