import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import * as R from 'ramda';
import {useNavigate} from 'react-router';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';
import isChanged from 'utils/stores/isChanged';

import {DSP_WizardFooterViewProps} from './WizardFooter.View';

export const useWizardFooter = (
  openDialog?: () => void,
): DSP_WizardFooterViewProps => {
  const {service, submitConfirmMessage} = useFormContext();
  const navigate = useNavigate();
  return useSelector(
    service,
    state => {
      const formIsChanged = isChanged(state.context);
      const canSave = state.context.authorisations?.updateById;
      const canSubmit = state.context.authorisations?.submit;

      const loading =
        state.matches('saving') ||
        state.matches('submitting') ||
        state.matches('archiving');
      const isSavingToArchive =
        state.matches('userInput.archived') || state.matches('archiving');
      const isValid = state.matches('userInput.valid');
      const metaHead = getStateMetaHead(state);
      const navigateTo = metaHead.navigateTo;
      const navigateState = metaHead.navigateState;

      return {
        loading,
        isSubmitButtonDisabled: !canSubmit || !isValid || loading,
        isSaveButtonDisabled: !canSave || loading || !formIsChanged,
        isPrintDisabled: loading,
        onSubmitClick: () => {
          if (submitConfirmMessage && openDialog) {
            openDialog();
            return;
          }
          service.send('SUBMIT');
        },
        onCancelClick: () => {
          service.send('CANCEL');
          if (!R.isNil(navigateTo) && navigateTo !== '') {
            navigate(
              navigateTo,
              navigateState ? {state: navigateState} : undefined,
            );
          }
        },
        onSaveClick: () => {
          if (isSavingToArchive && openDialog) {
            openDialog();
            return;
          }
          service.send('SAVE');
        },
        onPrintClick: () => {
          window.print();
        },
      };
    },
    (prevFieldProps, nextFieldProps) => {
      const ignoreProps = R.omit([
        'onSubmitClick',
        'onCancelClick',
        'onSaveClick',
        'onPrintClick',
      ]);
      return R.equals(ignoreProps(prevFieldProps), ignoreProps(nextFieldProps));
    },
  );
};
