import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';
import {ActivityStatusEnum} from '@regulatory-platform/common-utils-workflow/dist/enums/activityStatus.enum';
import {useUserProfile} from 'app/services/useUserProfile';
import {OutcomeType} from 'containers-workflow/Shared/types';
import {WorkflowActivitySystemType} from 'containers-workflow/Shared/types';

import {
  DSP_WorkflowActivityActionType,
  workflowActivityNotificationTypeMessageMap,
  workflowActivityTypeNameMap,
  outcomeTypeColorMap,
} from '../constants';
import {DSP_WorkflowActivityNotificationItem} from '../types';

export interface DSP_UseBaseWorkflowActivityNotificationCardProps {
  notification: DSP_WorkflowActivityNotificationItem;
}

export function useBaseWorkflowActivityNotificationCard({
  notification,
}: DSP_UseBaseWorkflowActivityNotificationCardProps) {
  const {accountType} = useUserProfile();
  const {created, readByLoggedInUser, data} = notification;

  return {
    isRead: readByLoggedInUser,
    created: created ?? undefined,
    title: getTitle(notification),
    href: getHref(notification, accountType),
    status: getStatus(notification),
    actionDescription: getActionDescription(
      notification,
      getMessages(notification),
    ),
    statusColor: outcomeTypeColorMap[data.outcomeType as OutcomeType],
  };
}

const getTitle = (
  notification: DSP_WorkflowActivityNotificationItem,
): string => {
  const {headerActivity, name} = notification.data;

  // for responses and replies to responses, we use the title (name) of the header activity
  if (headerActivity) {
    return headerActivity.name ?? '';
  }

  return name ?? '';
};

const getHref = (
  notification: DSP_WorkflowActivityNotificationItem,
  accountType: AuthorisationAccountTypes | undefined,
): string | undefined => {
  const {id, systemType, urlProperties} = notification.data;
  const activityId = notification.data.headerActivity?.id ?? id;

  let baseUrl: string | undefined;
  let effectiveSystemType = systemType;

  if (systemType === WorkflowActivitySystemType.DECISION_TASK) {
    effectiveSystemType = WorkflowActivitySystemType.TASK;
  }

  if (isChangeSetUrl(accountType, notification)) {
    baseUrl = `/networks/accessProductChangeSets/${notification.data.urlProperties?.accessProductDocumentId}/accessProductApplication/${urlProperties?.accessProductApplicationId}/network/${notification.data.urlProperties?.networkId}/nur/${notification.data.urlProperties?.networkAccessChangeSetId}`;
  } else if (isApplicationUrl(accountType, notification)) {
    baseUrl = `/networks/accessProductApplications/${urlProperties?.accessProductApplicationId}/network/${notification.data.urlProperties?.networkId}/view`;
  }

  if (!baseUrl) {
    return;
  }

  return `${baseUrl}?workflowTab=${effectiveSystemType}&activityType=${effectiveSystemType}&activityId=${activityId}`;
};

type DSP_UrlMatcherFunction = (
  accountType: AuthorisationAccountTypes | undefined,
  notification: DSP_WorkflowActivityNotificationItem,
) => boolean;

const isChangeSetUrl: DSP_UrlMatcherFunction = (accountType, notification) => {
  const {urlProperties} = notification.data;

  return (
    accountType === AuthorisationAccountTypes.PARTNER &&
    !!urlProperties?.networkId &&
    !!urlProperties?.networkAccessChangeSetId
  );
};

const isApplicationUrl: DSP_UrlMatcherFunction = (
  accountType,
  notification,
) => {
  const {urlProperties} = notification.data;

  return (
    accountType === AuthorisationAccountTypes.OPERATIONS &&
    !!urlProperties?.accessProductApplicationId
  );
};

const getStatus = (
  notification: DSP_WorkflowActivityNotificationItem,
): string => {
  const {headerActivity, status} = notification.data;

  if (headerActivity) {
    return headerActivity.status ?? '';
  }

  return status ?? '';
};

const getActionDescription = (
  notification: DSP_WorkflowActivityNotificationItem,
  messages: ReturnType<typeof getMessages>,
): string => {
  const {systemType} = notification.data;

  const activityName =
    workflowActivityTypeNameMap[systemType as WorkflowActivitySystemType];

  return messages
    ? messages.length === 2
      ? `${messages[0]} ${activityName} ${messages[1]}`
      : `${messages[0]} ${activityName}`
    : '';
};

const getMessages = (notification: DSP_WorkflowActivityNotificationItem) => {
  const {isReply, eventType, headerActivity, relatedActivity} =
    notification.data;

  let activityActionType = eventType as DSP_WorkflowActivityActionType;

  // Detect if this is a response or a reply to a response
  // NOTE: Responses seem to have inconsistent types (Tasks vs InfoReq's) so we can't use the map lookup approach here
  // We'll instead use the isReply setting
  // TODO: consider moving the logic to server-entities-workflow:
  // https://dev.azure.com/NHVRSolutions/Regulatory Platform/_git/server-entities-workflow?path=src/interceptor/utils/getStatusByUser.ts
  // https://dev.azure.com/NHVRSolutions/Regulatory Platform/_git/server-entities-workflow?path=/src/machines/common/notifications/activityNotificationBuilder.ts
  if (isReply) {
    // it is a reply to a response if headerActivityId and relatedActivityId both exist and differ
    if (
      headerActivity?.id &&
      relatedActivity?.id &&
      headerActivity?.id !== relatedActivity?.id
    ) {
      switch (notification.data.systemType) {
        case WorkflowActivitySystemType.INFORMATION:
          activityActionType =
            headerActivity?.status === ActivityStatusEnum.RESPONSE_SUBMITTED
              ? DSP_WorkflowActivityActionType.REPLY
              : DSP_WorkflowActivityActionType.RESPONSE;
          break;
        default:
          activityActionType = DSP_WorkflowActivityActionType.REPLY;
          break;
      }
    } else {
      activityActionType = DSP_WorkflowActivityActionType.RESPONSE;
    }
  }

  return workflowActivityNotificationTypeMessageMap[activityActionType];
};
