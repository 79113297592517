import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/:accreditationId/drivers',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationDrivers" */ './AccreditationDrivers'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accreditation Drivers',
  menuIcon: Today,
  authorisation: {
    apiKey: 'AccreditationDriverMapping.findCustomer',
    policies: [{role: 'customerAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation',
      to: '/accreditation',
    },
  ],
};
