import Layers from '@mui/icons-material/Layers';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleNetworkMap" */ './VehicleNetworkMap.Connector'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'National Network Map',
  menuDescription: 'Single national heavy vehicle network maps',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  category: 'Map',
  sections: [],
};
