import {Observable} from 'rxjs';
import {
  createMachine,
  EventObject,
  Interpreter,
  MachineConfig,
  State,
} from 'xstate';
import machineDefinition from './websocketMachine.json';
import {PubSubToken} from '../../typeDefs/types';
import {FetchResult} from '@apollo/client';
import {GroupDataMessage, WebPubSubClient} from '@azure/web-pubsub-client';
import {
  PubSubGroup,
  WebSocketMessage,
} from '@regulatory-platform/common-utils-notification';

export type WebSocketMachineContext = {
  pubSubToken?: PubSubToken | null;
  // subscriptions is a map of subscription keys to an object containing the subscription details and its message listeners
  subscriptions?: Map<string, SubscriptionWithMessageListeners>;
  /**
   * clients is an array. Whilst there will be one active (primary) client, we maintain multiple in our state to
   * support blue/green activation of the primary client. This allows us to have at-least-once-message-delivery (i.e.
   * to prevent message loss)
   */
  clients?: WebSocketMachinePubSubClient[];
};

export interface SubscriptionWithMessageListeners {
  group: PubSubGroup;
  // key is the subscription key, which is typically a component name
  messageListeners: Map<string, MessageListener>;
}

/**
 * MessageListener models the callback to be invoked when a message is received
 */
export type MessageListener = (message: WebSocketMessage) => void;

/**
 * SubscribeRequest models a subscription request, including the group to subscribe to and the message listener callback
 */
export interface SubscribeRequest {
  /**
   * An identifier for the subscription. This is used to unsubscribe and will typically be a component name
   */
  subscriptionKey: string;

  /**
   * The Group details to subscribe to
   */
  group: PubSubGroup;

  /**
   * The callback to be invoked when a message is received
   */
  messageListener: MessageListener;
}

export interface UnsubscribeRequest {
  subscriptionKey: string;
}

/**
 * WebSocketMachinePubSubClient is a wrapper around the WebPubSubClient that includes a flag to indicate whether this client is the primary
 * This allows us to handle blue/green activation of the primary (i.e. allows us to support at-least-once-message-delivery, with no message loss)
 */
export interface WebSocketMachinePubSubClient {
  client: WebPubSubClient;
  /**
   * Whether this client is the primary. All non-primary clients will be closed when the primary is connected and has message handlers configured
   */
  isPrimary: boolean;
}

export interface WebSocketMachineStateSchema {
  states: {
    idle: {};
    generatePubSubToken: {};
    setupEventListeners: {};
    informSubscribers: {};
    closeRetiredClients: {};
  };
}
export type WebSocketMachineInterpreter = Interpreter<
  WebSocketMachineContext,
  WebSocketMachineStateSchema,
  WebSocketEventObject
>;

export type WebSocketState = State<
  WebSocketMachineContext,
  WebSocketEventObject,
  WebSocketMachineStateSchema
>;
export type WebSocketObservable = Observable<WebSocketState>;

export interface WebSocketEventObject extends EventObject {
  data?: FetchResult;
  subscribeRequest?: SubscribeRequest;
  unsubscribeRequest?: UnsubscribeRequest;
  groupMessage?: GroupDataMessage;
}

export type WebSocketConfig = MachineConfig<
  WebSocketMachineContext,
  WebSocketMachineStateSchema,
  WebSocketEventObject
>;

const machine = createMachine<WebSocketMachineContext, WebSocketEventObject>(
  machineDefinition as WebSocketConfig,
);
export default machine;
