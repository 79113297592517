import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/applications/establish/info',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationEstablishInfo" */ './AccreditationEstablishInfo'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Establish Accreditation',
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.create',
    policies: [{role: 'customerAccreditationUser'}] as AuthorisationPolicy[],
  },
  sections: [],
};
