import React from 'react';
import {DSL_ExternalLink} from 'components/DesignSystem/Library';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {ContentKeys, ContentMap, DomainContentMap} from 'containers-content';

export type ContentKeysContainerAccreditation =
  | 'ACCRED_SCHEME_NAME_LONG'
  | 'ACCRED_SCHEME_NAME_SHORT'
  | 'ACCRED_ESTABLISH_WELCOME_TEXT'
  | 'ACCRED_ESTABLISH_INFO_POINTS'
  | 'ACCRED_ESTABLISH_START_INFO'
  | 'ACCRED_ESTABLISH_AUDIT_DETAILS'
  | 'ACCRED_OVERVIEW_DASHBOARD_SUBTITLE'
  | 'ACCRED_OVERVIEW_DASHBOARD_ACCRED_ID_NUMBER_TITLE'
  | 'ACCRED_AUDITOR_PAGE_SUBTITLE'
  | 'ACCRED_MANAGE_VEHICLES_SUBTITLE'
  | 'ACCRED_MANAGE_ADD_VEHICLES_BULLETS'
  | 'ACCRED_MANAGE_UPDATE_VEHICLES_BULLETS';

const nhvr: ContentMap<ContentKeysContainerAccreditation> = {
  ACCRED_SCHEME_NAME_SHORT: 'NHVAS',
  ACCRED_SCHEME_NAME_LONG: (
    <>National Heavy Vehicle Accreditation Scheme (NHVAS)</>
  ),
  ACCRED_ESTABLISH_WELCOME_TEXT: [
    <>
      The National Heavy Vehicle Accreditation Scheme (NHVAS) is a formal
      process for recognising Operators who have robust safety and other
      management systems in place. It is also increasingly being used to show
      compliance with general duty requirements under road transport law.
    </>,
  ],
  ACCRED_ESTABLISH_INFO_POINTS: [
    <>
      A compliance management system in place in line with relevant standards.
    </>,
    <>
      You need to have a signed National Heavy Vehicle Accreditation Audit
      Report.
    </>,
    <>
      All documentation and forms applicable to your selected module, as set out
      in the relevant module guides.
    </>,
    <>
      A copy of the NHVAS-approved Auditor{"'"}s Audit Summary Report Note: A
      list of approved Auditors is available on the portal.
    </>,
    <>Your payment.</>,
    <>
      You will need Legal Declaration completed by the Company Director or the
      Individual Accreditation holder to complete this application.
    </>,
    <>
      For vehicles added to the Maintenance Module with a NT, NSW or QLD
      registration or registrations completed within the last 12 months a Road
      Worthiness Certificate attachment is not required.
    </>,
  ],
  ACCRED_ESTABLISH_START_INFO: (
    <>
      Begin your journey by selecting from our comprehensive modules: Mass
      Management, Maintenance Standards, Basic Fatigue Management, and Advanced
      Fatigue Management. Tailor your Accreditation experience to suit your
      needs and ensure compliance effortlessly.
    </>
  ),
  ACCRED_ESTABLISH_AUDIT_DETAILS: (
    <>
      You must provide a signed Audit Report from an NHVR-approved Auditor. You
      may use the same Auditor for all modules - provided they are approved for
      all modules. You may also use different Auditors for different modules if
      you wish. A complete list of NHVR-approved Auditors can be{' '}
      <DSL_ExternalLink
        href="https://www.service.nhvr.gov.au/#page=informationHub/nHVASApprovedAuditors"
        underline="none"
      >
        found here
      </DSL_ExternalLink>
      .
    </>
  ),
  ACCRED_OVERVIEW_DASHBOARD_SUBTITLE: (
    <>
      Welcome to NHVR Accreditation Management Dashboard. Here you can
      efficiently manage your accreditation modules and related activities.
    </>
  ),
  ACCRED_OVERVIEW_DASHBOARD_ACCRED_ID_NUMBER_TITLE: <>NHVAS No.</>,
  ACCRED_AUDITOR_PAGE_SUBTITLE: (
    <>
      This page contains the list of NHVAS Approved Auditors who can audit an
      NHVAS management system on behalf of the NHVR.
    </>
  ),
  ACCRED_MANAGE_VEHICLES_SUBTITLE: (
    <>
      All vehicles that are currently registered to be part of your Mass or
      Maintenance Accreditation.
    </>
  ),
  ACCRED_MANAGE_ADD_VEHICLES_BULLETS: [
    <>You must submit an application to add vehicles to the Accreditation.</>,
    <>
      Scan the Roadworthy Certificates if you are adding new vehicles to attach
      to your Application (Note: Vehicles registered in NT, NSW or QLD do not
      require a Roadworthiness Certificate to be scanned or attached to your
      Application).
    </>,
    <>Have a credit card available to pay any applicable fee(s).</>,
  ],
  ACCRED_MANAGE_UPDATE_VEHICLES_BULLETS: [
    <>
      You must submit an application to make changes to the Accredited vehicles.
    </>,
    <>
      You can change details of a vehicle or remove vehicles from Accreditation
      using this application.
    </>,
  ],
};

const wa: ContentMap<ContentKeysContainerAccreditation> = {
  ACCRED_SCHEME_NAME_SHORT: 'WAHVA',
  ACCRED_SCHEME_NAME_LONG: (
    <>Western Australian Heavy Vehicle Accreditation (WAHVA)</>
  ),
  ACCRED_ESTABLISH_WELCOME_TEXT: [
    <>
      Western Australian Heavy Vehicle Accreditation (WAHVA) is mandatory for
      anyone requiring a permit or order to perform any transport task within
      Western Australia, including interstate Operators, which requires
      Operators to comply with a minimum set of System Management Standards.
    </>,
    <>
      Western Australian Heavy Vehicle Accreditation (WAHVA){' '}
      <DSL_ExternalLink
        href={
          'https://www.mainroads.wa.gov.au/globalassets/heavy-vehicles/accreditation/wahva-business-rules.pdf'
        }
      >
        Business Rules
      </DSL_ExternalLink>{' '}
      set out the required steps to become accredited, how to maintain
      Accreditation and how an Operator’s Accreditation can be cancelled or
      suspended.
    </>,
  ],
  ACCRED_ESTABLISH_INFO_POINTS: [
    <>
      Compliant management systems that meet the requirements of the relevant
      standards.
    </>,
    <>
      You must contact and choose a Western Australian Heavy Vehicle
      Accreditation (WAHVA) certified Auditor to conduct an Audit. The Audit
      forms part of this process. Certified Auditors can be{' '}
      <DSL_ExternalLink
        href={
          'https://www.mainroads.wa.gov.au/heavy-vehicles/accreditation-auditing/status/'
        }
      >
        found here
      </DSL_ExternalLink>
      .
    </>,
    <>
      You will need a Legal Declaration completed by the authorised company
      representative or the individual Accreditation holder to complete the
      application.
    </>,
  ],
  ACCRED_ESTABLISH_START_INFO: (
    <>
      Dimension & Loading Management, Maintenance Management and Fatigue
      Management are all mandatory modules under WAHVA. The Mass Management
      Module is only required when operating in excess of the prescribed mass
      limits with an efficient concessional loading scheme.
    </>
  ),
  ACCRED_ESTABLISH_AUDIT_DETAILS: (
    <>
      Your systems must be audited by a Certified WA Heavy Vehicle Accreditation
      Scheme (WAHVA) Auditor to verify that they are compliant and that you have
      sufficient records and documentation to demonstrate adherence.
    </>
  ),
  ACCRED_OVERVIEW_DASHBOARD_SUBTITLE: (
    <>
      Welcome to The Western Australian Heavy Vehicle Accreditation (WAHVA)
      Dashboard. Here you can efficiently manage your accreditation modules and
      related activities.
    </>
  ),
  ACCRED_OVERVIEW_DASHBOARD_ACCRED_ID_NUMBER_TITLE: <>Operator No.</>,
  ACCRED_AUDITOR_PAGE_SUBTITLE: (
    <>
      This page contains the list of WAHVA Approved Auditors who can audit a
      WAHVA management system on behalf of Main Roads Western Australia. The
      Auditors below are all certified to audit the Fatigue Management,
      Dimension & Load Management and Maintenance Management Modules. In
      addition, Auditors whom are certified to audit the Mass Management Module
      are indicated below.
    </>
  ),
  ACCRED_MANAGE_VEHICLES_SUBTITLE: (
    <>
      List of all powered vehicles, dollies and trailers you want to operate
      under WAHVA.
    </>
  ),
  ACCRED_MANAGE_ADD_VEHICLES_BULLETS: [
    <>
      Add all powered vehicles, dollies and trailers you want to operate under
      WAHVA.
    </>,
    <>
      The Manufacturer’s GCM Rating, ATM Rating, AMMS/PBS Permit number and
      AMMS/PBS Permit Expiry Date fields are only required for vehicles
      operating under the Mass Management Module.
    </>,
  ],
  ACCRED_MANAGE_UPDATE_VEHICLES_BULLETS: [
    <>
      You must submit an Application to make changes to your Accredited
      vehicles.
    </>,
    <>
      The Manufacturer’s GCM Rating, ATM Rating, AMMS/PBS Permit number and
      AMMS/PBS Permit Expiry Date fields are only required for vehicles
      operating under the Mass Management Module.
    </>,
  ],
};

const adf: ContentMap<ContentKeysContainerAccreditation> = {};

export default {
  nhvr,
  wa,
  adf,
} as DomainContentMap<AuthorisationDomains, ContentKeys>;
