import React from 'react';

import {DSL_TabPanelProps} from './TabPanel';

export const DSL_FragmentTabPanel = ({
  children,
  value,
  label,
}: DSL_TabPanelProps) => {
  return <>{value === label && children}</>;
};
