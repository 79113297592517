import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils';
import {JSONSchemaRecord} from '@regulatory-platform/common-utils/dist';
import {JSONSchema7TypeName, JSONSchema7} from 'json-schema';
import * as R from 'ramda';

import {DSP_CheckboxFieldProps} from '../CheckboxField';
import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {DSP_MultiSelectAutoCompleteFieldProps} from '../MultiSelectAutocompleteField';
import {DSP_NumberFieldProps} from '../NumberField';
import {DSP_SingleSelectFieldProps} from '../SingleSelectField';
import {DSP_TextFieldProps} from '../TextField';

type DSP_FieldPropsUnion =
  | DSP_TextFieldProps
  | DSP_NumberFieldProps
  | DSP_CheckboxFieldProps
  | DSP_SingleSelectFieldProps
  | DSP_MultiSelectAutoCompleteFieldProps;

export type DSP_FieldType =
  | JSONSchema7TypeName
  | 'selectMulti'
  | 'selectSingle'
  | 'dateTime'
  | 'notApplicable'
  | undefined;

export type DSP_UseFormFieldProps = DSP_FieldPropsUnion & {
  /** only use this when the default field derived from the schema is incorrect */
  fieldTypeOverride?: DSP_FieldType;
};

export const useFormField = ({
  fieldRef,
  hideLabel,
  placeholder,
  label,
  fieldTypeOverride,
  ...propsOverrides
}: DSP_UseFormFieldProps) => {
  const service = useFormService();

  const {fieldType, fieldProps} = useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const {schema} = state.context;

      const schemaType =
        fieldTypeOverride ?? getFieldTypeFromSchema(fieldRef, schema);

      const shouldHideLabel = R.defaultTo(schemaType === 'boolean', hideLabel);
      const labelProps = shouldHideLabel
        ? {label: '', hideLabel: true}
        : {label};

      return {
        fieldType: schemaType,
        fieldProps: {
          fieldRef,
          placeholder,
          ...labelProps,
        },
      };
    },
  );

  return {
    fieldType,
    fieldProps: {
      ...fieldProps,
      ...propsOverrides,
    },
  };
};

function getFieldTypeFromSchema(
  fieldRef: string,
  schema: JSONSchema7,
): DSP_FieldType {
  const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);

  if (!fieldSchema) {
    return undefined;
  }

  const type = fieldSchema.type as JSONSchema7TypeName;
  const format = fieldSchema.format;

  if (type === 'string' && (format === 'date' || format === 'date-time')) {
    return 'dateTime';
  }

  if (fieldSchema.enum || (fieldSchema['x-items'] as JSONSchemaRecord)?.enum) {
    return type === 'array' ? 'selectMulti' : 'selectSingle';
  }

  if (['object', 'array'].includes(type)) {
    return undefined;
  }

  return type;
}
