import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';

export const useFormSaveConfirmationDialog = (onClose: () => void) => {
  const {service} = useFormContext();
  return {
    onConfirm: () => {
      service.send('SUBMIT');
      onClose();
    },
  };
};
