import {
  CommonValidator,
  jsonPointerSettor,
  schemaJsonPointerSettor,
  validatorExec,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext, FormRecord} from './types';

export default function onChangeBlurEventHandler<T extends FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
): FormMachineContext<T> {
  const {fieldRef, value, fieldRefRelation, valueRelation} = event;

  const {record: origRecord, schema: origSchema, shared} = context;
  const isNewRecord = fieldRef.indexOf('/-1/') > -1;
  let record = jsonPointerSettor(fieldRef, value)(origRecord);
  if (!isNewRecord && !R.isNil(fieldRefRelation)) {
    record = jsonPointerSettor(fieldRefRelation, valueRelation)(record);
  }
  const schema = schemaJsonPointerSettor(
    fieldRef,
    'x-valid/touched',
    true,
  )(origSchema);
  const result = validatorExec(
    {
      record,
      origRecord,
      schema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared: R.mergeRight(R.defaultTo({}, shared), {fieldRef}),
    },
    validator,
  );
  const nextContext = context;
  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== result.validatedSchema) {
    nextContext.schema = result?.validatedSchema;
  }
  return nextContext;
}
