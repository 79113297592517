import {useCallback, useMemo} from 'react';

import {useInterpret, useSelector} from '@xstate/react';
import {equals} from 'ramda';
import isValid from 'utils/stores/isValid';
import {FormEventObject} from 'utils/stores/types';

import {
  CsvRowUpdatedEvent,
  ImportFromCsvRowMachineContext,
  ImportFromCsvService,
} from '../machines/types';

import {ImportFromCsvRowProps} from './ImportFromCsvRow';

export function useImportFromCsvRow({
  csvService,
  rowMachine,
  ...props
}: ImportFromCsvRowProps) {
  const service = useInterpret<ImportFromCsvRowMachineContext, FormEventObject>(
    rowMachine,
    {
      actions: {
        onRowUpdated: useMemo(
          () => createOnRowUpdated(csvService),
          [csvService],
        ),
      },
    },
  );

  const stateDerivedProps = useSelector(
    service,
    state => ({
      error: !isValid(state.context),
      checked: state.context.isSelected,
    }),
    equals,
  );

  const toggleChecked = useCallback(
    () => service.send('TOGGLE_SELECTED'),
    [service],
  );

  return {
    ...props,
    ...stateDerivedProps,
    service,
    toggleChecked,
  };
}

function createOnRowUpdated(csvService: ImportFromCsvService) {
  return (context: ImportFromCsvRowMachineContext) => {
    const {record, lineIndex, isSelected} = context;
    csvService.send({
      record,
      lineIndex,
      isSelected,
      type: 'ROW_UPDATED',
      isValid: isValid(context),
    } as CsvRowUpdatedEvent);
  };
}
