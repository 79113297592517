import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Intercom = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.317 12.455c0 .17-.07.332-.195.452a.68.68 0 01-.47.187.68.68 0 01-.47-.187.626.626 0 01-.195-.452V6.75c0-.17.07-.332.195-.452a.68.68 0 01.47-.187.68.68 0 01.47.187c.125.12.195.283.195.452v5.705zm-.23 3.953c-.089.085-2.563 2.064-7.098 2.064s-6.993-1.969-7.097-2.053a.633.633 0 01-.23-.428.605.605 0 01.153-.459.684.684 0 01.935-.069c.038.027 2.248 1.747 6.233 1.747 3.986 0 6.213-1.73 6.235-1.746a.695.695 0 01.94.068.613.613 0 01-.066.882l-.006-.006zM4.654 6.75c.01-.17.09-.33.222-.443a.688.688 0 01.917.024.627.627 0 01.192.419v5.695c0 .17-.07.332-.195.451a.68.68 0 01-.47.187.68.68 0 01-.47-.187.626.626 0 01-.196-.451V6.75zm3.337-1.267c.01-.17.09-.329.222-.442a.688.688 0 01.917.023.627.627 0 01.192.42v8.444c0 .17-.07.332-.195.451a.68.68 0 01-.47.187.68.68 0 01-.47-.187.626.626 0 01-.196-.451V5.483zm3.354-.316c0-.17.07-.332.195-.452a.68.68 0 01.47-.187.68.68 0 01.47.187c.125.12.195.282.195.452v9.183c0 .17-.07.332-.195.452a.68.68 0 01-.47.187.68.68 0 01-.47-.187.626.626 0 01-.195-.452V5.167zm3.298.316c0-.169.07-.331.195-.451a.68.68 0 01.47-.187.68.68 0 01.47.187c.126.12.196.282.196.451v8.445c0 .17-.07.332-.195.451a.68.68 0 01-.47.187.68.68 0 01-.47-.187.626.626 0 01-.196-.451V5.483zM19.5 2H4.5a2.576 2.576 0 00-.953.176 2.496 2.496 0 00-.81.513c-.233.221-.418.483-.544.773-.127.289-.193.6-.194.913v14.25c.001.314.067.624.194.913.126.29.311.552.544.773.232.22.507.395.81.513.303.118.627.178.953.176H19.5c.326.002.65-.058.952-.176.302-.118.577-.292.81-.512.232-.22.417-.481.544-.77.127-.289.193-.599.195-.912V4.375a2.303 2.303 0 00-.193-.912 2.38 2.38 0 00-.543-.772 2.494 2.494 0 00-.808-.513A2.575 2.575 0 0019.504 2"
            fill="#fff"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Intercom);
