import MapIcon from '@mui/icons-material/Map';
import VehicleNetworkMap from 'containers-map/VehicleNetworkMap';
import ManageSubscriptions from 'containers-map/ManageSubscriptions';

export default {
  routes: [ManageSubscriptions, VehicleNetworkMap],
  menuTitle: 'Maps',
  menuDescription: 'National Network Map',
  path: '/maps',
  showChildRoutesInMenu: true,
  menuIcon: MapIcon,
};
