import {NotificationType} from 'utils/machines/notificationsMachine';

export const roadManagementTypeMessageMap: {
  [DSP_Type in NotificationType]?: string[] | undefined;
} = {
  [NotificationType.ROAD_MANAGEMENT_PROPOSAL]: [
    'wants to update the management of road segments in',
    'Please review.',
  ],
  [NotificationType.ROAD_MANAGEMENT_ACCEPT]: [
    'agrees with the management update to',
  ],
  [NotificationType.ROAD_MANAGEMENT_REJECT]: [
    'disagrees with the management update to',
  ],
  [NotificationType.ROAD_MANAGEMENT_AUTO_TRANSFER]: [
    'has updated the management of road segments in',
  ],
  [NotificationType.ROAD_MANAGEMENT_CLAIM_CO_MANAGED]: [
    'has claimed full management of co-managed road segments in',
  ],
};
