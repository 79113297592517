import React, {ReactNode} from 'react';

import {DSL_BackIconButton} from '../../../Buttons/IconButtons/BackIconButton';
import {DSL_CloseIconButton} from '../../../Buttons/IconButtons/CloseIconButton';
import {DSL_Heading5} from '../../../Typography/Heading5';
import {DSL_Flex} from '../../Flex';
import {DSL_FlexColumn} from '../../FlexColumn';
import {DSL_FlexRow} from '../../FlexRow';

import {
  DSL_BasePopperHeader,
  DSL_BasePopperHeaderProps,
} from './BasePopperHeader';

export interface DSL_PopperHeaderProps {
  title: ReactNode;
  padding?: DSL_BasePopperHeaderProps['padding'];
  onClose?: () => void;
  onBack?: () => void;
}

export const DSL_PopperHeader = ({
  padding,
  title,
  onBack,
  onClose,
}: DSL_PopperHeaderProps) => {
  return (
    <DSL_BasePopperHeader padding={padding}>
      <DSL_FlexRow
        flexShrink={0}
        width={'100%'}
        boxSizing={'border-box'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <DSL_FlexColumn gap={1}>
          <DSL_FlexRow gap={1} alignItems={'center'} wordBreak={'break-word'}>
            {onBack && <DSL_BackIconButton onClick={onBack} />}
            <DSL_Heading5>{title}</DSL_Heading5>
          </DSL_FlexRow>
        </DSL_FlexColumn>
        {onClose && (
          <DSL_Flex justifyContent={'flex-end'} alignItems={'flex-start'}>
            <DSL_CloseIconButton onClick={onClose} />
          </DSL_Flex>
        )}
      </DSL_FlexRow>
    </DSL_BasePopperHeader>
  );
};

export {
  //
  /** @deprecated use DSL_PopperHeaderProps instead*/
  DSL_PopperHeaderProps as PopperHeaderProps, //
  /** @deprecated use DSL_PopperHeader instead*/
  DSL_PopperHeader as PopperHeader,
};
