import React, {ReactNode} from 'react';

import {DSL_BackIconButton} from '../../Buttons/IconButtons/BackIconButton';
import {DSL_CloseIconButton} from '../../Buttons/IconButtons/CloseIconButton';
import {DSL_Flex} from '../../Layout/Flex';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_FlexRow} from '../../Layout/FlexRow';
import {DSL_Breadcrumbs} from '../../Layout/PageHeader/Breadcrumbs';
import {DSL_Body1} from '../../Typography/Body1';
import {DSL_Heading5} from '../../Typography/Heading5';

import {DSL_BaseDrawerHeader} from './BaseDrawerHeader/BaseDrawerHeader';

export interface DSL_DrawerHeaderProps {
  title: ReactNode;
  description?: ReactNode;
  breadcrumbs?: ReactNode[];
  padding?: 'SM' | 'MD' | 'LG';
  onClose?: () => void;
  onBack?: () => void;
  disableCloseButton?: boolean;
}

export const DSL_DrawerHeader = ({
  title,
  padding,
  description,
  breadcrumbs,
  onClose,
  onBack,
  disableCloseButton,
}: DSL_DrawerHeaderProps) => {
  return (
    <DSL_BaseDrawerHeader padding={padding}>
      <DSL_FlexRow
        flexShrink={0}
        width={'100%'}
        boxSizing={'border-box'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <DSL_FlexColumn gap={1}>
          {breadcrumbs && <DSL_Breadcrumbs>{breadcrumbs}</DSL_Breadcrumbs>}
          <DSL_FlexRow gap={1} alignItems={'center'} flexWrap={'nowrap'}>
            {onBack && <DSL_BackIconButton onClick={onBack} />}
            <DSL_Heading5 ellipsisLines={1}>{title}</DSL_Heading5>
          </DSL_FlexRow>
          {description && (
            <DSL_Body1 color={'text.secondary'}>{description}</DSL_Body1>
          )}
        </DSL_FlexColumn>
        {onClose && (
          <DSL_Flex justifyContent={'flex-end'} alignItems={'flex-start'}>
            <DSL_CloseIconButton
              disabled={disableCloseButton}
              onClick={onClose}
            />
          </DSL_Flex>
        )}
      </DSL_FlexRow>
    </DSL_BaseDrawerHeader>
  );
};

DSL_DrawerHeader.displayName = 'DSL_DrawerHeader';

export {
  //
  /** @deprecated use DSL_DrawerHeaderProps instead*/
  DSL_DrawerHeaderProps as DrawerHeaderProps, //
  /** @deprecated use DSL_DrawerHeader instead*/
  DSL_DrawerHeader as DrawerHeader,
};
