import React, {CSSProperties, ReactNode, RefObject} from 'react';

import {
  Drawer,
  Paper,
  DrawerProps,
  drawerClasses,
  Theme,
  useTheme,
} from '@mui/material';

import {DSL_DrawerContainer} from '../DrawerContainer';
import {
  DSL_DrawerAnchor,
  DSL_DrawerPositionProps,
  DSL_RestrictedDrawerProps,
} from '../types';

export interface DSL_BaseDrawerProps
  extends DSL_RestrictedDrawerProps,
    DSL_DrawerPositionProps {
  anchor?: DSL_DrawerAnchor;
  border?: CSSProperties['border'];
  muiVariant?: DrawerProps['variant'];
  width?: CSSProperties['width'];
  maxWidth?: CSSProperties['width'];
  height?: CSSProperties['height'];
  zIndex?: number;
  isHidden?: boolean;
  sidebar?: ReactNode;
  sidebarAnchor?: DSL_DrawerAnchor;
  sidebarPosition?: DSL_DrawerPositionProps;
  sidebarTransition?: CSSProperties['transition'];
  refSidebar?: RefObject<HTMLDivElement>;
  children?: ReactNode;
  isMinimized?: boolean;
}

export const DSL_BaseDrawer = ({
  children,
  anchor,
  open,
  sidebar,
  refSidebar,
  muiVariant,
  sidebarPosition,
  sidebarAnchor,
  sidebarTransition,
  width,
  maxWidth,
  height,
  top,
  left,
  right,
  bottom,
  zIndex,
  border,
  isHidden,
  isMinimized,
  ...props
}: DSL_BaseDrawerProps) => {
  const theme: Theme = useTheme();
  return (
    <>
      {sidebar && (
        <Paper
          square
          ref={refSidebar}
          sx={{
            position: 'fixed',
            transition: sidebarTransition,
            zIndex: zIndex ? zIndex + 1 : zIndex,
            visibility: isHidden ? 'hidden' : 'visible',
            ...sidebarPosition,
          }}
        >
          {sidebar}
        </Paper>
      )}
      <Drawer
        data-name={'base-drawer'}
        id={'base-drawer'}
        open={open}
        anchor={anchor}
        variant={muiVariant}
        PaperProps={{
          elevation: theme.elevation.drawers,
        }}
        sx={{
          zIndex,
          visibility: isHidden ? 'hidden' : 'visible',
          [`&>.${drawerClasses.paper}`]: {
            border,
            zIndex,
            width,
            maxWidth,
            height,
            top,
            left,
            right,
            bottom,
            boxSizing: 'content-box',
          },
        }}
        {...props}
      >
        <DSL_DrawerContainer width={'100%'} height={'100%'}>
          {children}
        </DSL_DrawerContainer>
      </Drawer>
    </>
  );
};

DSL_BaseDrawer.displayName = 'DSL_BaseDrawer';

export {
  //
  /** @deprecated use DSL_BaseDrawerProps instead*/
  DSL_BaseDrawerProps as BaseDrawerProps, //
  /** @deprecated use DSL_BaseDrawer instead*/
  DSL_BaseDrawer as BaseDrawer,
};
