/**
 * 💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀
 * 💀            WOAH THERE!!!         💀
 * 💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀💀
 *
 * NOTE: these are designed to be *HIGHLY* reusable renderers across multiple schemas.
 * if you're trying to create a one-off renderer you should be doing that by either passing in a custom valueRenderer function
 * or a component in your summary config rather than adding it here.
 */
import React, {ReactNode} from 'react';

import {JSONSchemaRecord, ValueType} from '@regulatory-platform/common-utils';
import {DSP_DateTimeRenderer} from 'components/DesignSystem/Portal/DateTime/DateTimeRenderer/DateTimeRenderer';
import {parseISO} from 'date-fns';
import * as R from 'ramda';
import {RelatedRecord} from 'utils/stores/types';

import {DSL_Box} from 'components/DesignSystem/Library';

import {DSP_LongDateRenderer} from '../../../DateTime/LongDateRenderer/LongDateRenderer';
import {DSP_ShortDateRenderer} from '../../../DateTime/ShortDateRenderer/ShortDateRenderer';

type DSP_ValueRendererFunction = (
  value: ValueType,
  schema: JSONSchemaRecord,
) => {
  value?: ReactNode;
  suffix?: ReactNode;
};

export const stringRenderer: DSP_ValueRendererFunction = value => ({
  value,
});

export const htmlRenderer: DSP_ValueRendererFunction = value => ({
  value: (
    <DSL_Box
      // small css reset to prevent margins on p tags. We may need a more full fledged style here later
      sx={{
        '& > *': {
          mt: 0,
          mb: 2,
        },
        '& > *:last-child': {
          mb: 0,
        },
      }}
      dangerouslySetInnerHTML={{__html: `${R.defaultTo('-', value)}`}}
    />
  ),
});

export const numberRenderer: DSP_ValueRendererFunction = (value, schema) => {
  const numericValue = value as number | null;
  const formattingConfig = schema?.['x-formatting'];
  const suffix = formattingConfig?.uom || formattingConfig?.baseUom;
  const uomConversion = formattingConfig?.uomConversion || 1;
  return {
    value: numericValue ? uomConversion * numericValue : null,
    suffix: suffix,
  };
};

export const booleanRenderer: DSP_ValueRendererFunction = value => {
  if (R.is(Boolean, value)) {
    return {
      value: value ? 'Yes' : 'No',
    };
  } else if (!R.isNil(value)) {
    return {
      value: 'No',
    };
  }
  return {};
};

export const arrayRenderer: DSP_ValueRendererFunction = value => ({
  value: (value as string[]).join(', '),
});

export const dateTimeRenderer: DSP_ValueRendererFunction = value => ({
  value: value ? (
    <DSP_DateTimeRenderer date={parseISO(value as string)} />
  ) : null,
});

export const dateShortRenderer: DSP_ValueRendererFunction = value => ({
  value: value ? (
    <DSP_ShortDateRenderer date={parseISO(value as string)} />
  ) : null,
});

export const dateLongRenderer: DSP_ValueRendererFunction = value => ({
  value: value ? (
    <DSP_LongDateRenderer date={parseISO(value as string)} />
  ) : null,
});

/**
 * This is a very simple renderer which attempts to render an object field by looking for a name attribute.
 * If that doesnt apply to the object you're rendering you should provide a custom valueRenderer
 * @param value
 * @param schema
 */
export const objectWithNameRenderer: DSP_ValueRendererFunction = (
  value,
  schema,
) => {
  if (schema?.properties?.name === undefined) {
    throw new Error(
      'Summary error: Trying to render an object type without a name attribute.  You need to pass a custom valueRenderer.',
    );
  }
  return {
    value: (value as RelatedRecord)?.name,
  };
};
