import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Maintenance = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 8a2 2 0 012-2v2h-.286v6H13V8h-1V6h2a2 2 0 012 2h1.281a1 1 0 01.904.572l1.041 2.197H21a1 1 0 011 1V15a1 1 0 01-1 1h-2.05a2.5 2.5 0 11-4.9 0h-5.1a2.5 2.5 0 11-4.9 0H2V8zm4.5 8a.5.5 0 100 1 .5.5 0 000-1zm10 0a.5.5 0 100 1 .5.5 0 000-1zm4-2v-2h-2.315L17 9.5h-2V14h5.5z"
            fill="#000"
            fillOpacity={0.54}
          />
          <path
            d="M9.272 9.247a1.689 1.689 0 00-.394-1.757 1.684 1.684 0 00-1.99-.287l1.14 1.141-.684.685-1.14-1.141a1.683 1.683 0 00.286 1.99 1.689 1.689 0 001.757.394l1.656 1.655a.24.24 0 00.345 0l.68-.68a.24.24 0 000-.344L9.271 9.247z"
            fill="#000"
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Maintenance);
