import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Mass = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 6h1v2H3.714v6H13V8h-2V6h3a2 2 0 012 2h1.281a1 1 0 01.904.572l1.041 2.197H21a1 1 0 011 1V15a1 1 0 01-1 1h-2.05a2.5 2.5 0 11-4.9 0h-5.1a2.5 2.5 0 11-4.9 0H2V8a2 2 0 012-2zm2.5 10a.5.5 0 100 1 .5.5 0 000-1zm10 0a.5.5 0 100 1 .5.5 0 000-1zm4-2v-2h-2.315L17 9.5h-2V14h5.5z"
            fill="#000"
            fillOpacity={0.54}
          />
          <path
            d="M9.745 8.865L10.76 9.88l-2.88 2.88L5 9.88l1.015-1.015 1.145 1.137V7H8.6v3.002l1.145-1.137z"
            fill="#000"
            fillOpacity={0.54}
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Mass);
