import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/assets',
  component: lazy(() => import(/* webpackChunkName: "Assets" */ './Assets')),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Asset capability',
  menuDescription:
    'Tools to define the heavy vehicle access capability of bridges & culverts',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'AssetDetailWithRelations.findById',
    policies: [
      {
        role: 'partnerAccessRead',
      },
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'providerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
