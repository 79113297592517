import React, {ReactNode} from 'react';

import {DSL_FlexColumn} from 'components/DesignSystem/Library/Layout/FlexColumn';
import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';
import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';
import {DSL_Body2} from 'components/DesignSystem/Library/Typography/Body2';
import {DSL_Caption} from 'components/DesignSystem/Library/Typography/Caption';

export type DSL_BaseSummaryItemProps = {
  children?: ReactNode;
  label?: ReactNode;
  suffix?: ReactNode;
  emptyState?: ReactNode;
  ellipsisLines?: number;
  variant?: 'table' | 'page';
  /** use the secondary text styles */
  isSecondary?: boolean;
};

export const DSL_BaseSummaryItem = ({
  children,
  label,
  suffix,
  ellipsisLines = 1,
  variant = 'page',
  emptyState = '-',
  isSecondary = false,
}: DSL_BaseSummaryItemProps) => {
  const DSL_VariantComponent = variant === 'table' ? DSL_Body2 : DSL_Body1;
  const color = isSecondary ? 'text.secondary' : 'text.primary';
  return (
    <DSL_FlexColumn gap={0.5}>
      {label && <DSL_Caption color={'text.secondary'}>{label}</DSL_Caption>}
      <DSL_FlexRow gap={0.5} alignItems={'baseline'}>
        <DSL_VariantComponent
          color={color}
          component={'div'}
          ellipsisLines={ellipsisLines}
          title={typeof children === 'string' ? children : undefined}
        >
          {children ?? emptyState}
        </DSL_VariantComponent>
        {suffix && <DSL_Caption color={'text.secondary'}>{suffix}</DSL_Caption>}
      </DSL_FlexRow>
    </DSL_FlexColumn>
  );
};

DSL_BaseSummaryItem.displayName = 'DSL_BaseSummaryItem';

export {
  //
  /** @deprecated use DSL_BaseSummaryItemProps instead*/
  DSL_BaseSummaryItemProps as BaseSummaryItemProps, //
  /** @deprecated use DSL_BaseSummaryItem instead*/
  DSL_BaseSummaryItem as BaseSummaryItem,
};
