import React from 'react';

import {ApolloQueryResult} from '@apollo/client';
import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

import {
  DSP_SearchRelationField,
  DSP_SearchRelationFieldProps,
} from '../SearchRelationField';
import getAccountTypeName from '../utils/getAccountTypeName';

import {CustomerAccountsSearchDocument} from './api/query/CustomerAccountSearch.generated';
import {OperationsAccountsSearchDocument} from './api/query/OperationsAccountSearch.generated';
import {PartnerAccountsSearchDocument} from './api/query/PartnerAccountSearch.generated';
import {
  ProviderAccountsSearchDocument,
  ProviderAccountsSearchQuery,
} from './api/query/ProviderAccountSearch.generated';

export interface DSP_AccountSearchRelationFieldProps
  extends Omit<DSP_SearchRelationFieldProps, 'query' | 'queryVariables'> {
  accountType: AuthorisationAccountTypes;
  providerTypes?: string[];
}

const filterProviderAccountsByProviderType = (
  result: ApolloQueryResult<ProviderAccountsSearchQuery>,
  providerTypesToFilter: string[],
) => {
  const filteredResults = result.data?.providerAccountsSearch?.filter(
    providerAccount => {
      const providerTypes = providerAccount?.providerTypes || [];
      return (
        R.intersection(providerTypesToFilter as string[], providerTypes)
          .length > 0
      );
    },
  );
  return {
    ...result,
    data: {
      providerAccountsSearch: filteredResults,
    },
  };
};

const accountTypeToLabelQueryMap = {
  [AuthorisationAccountTypes.CUSTOMER]: {
    label: getAccountTypeName(AuthorisationAccountTypes.CUSTOMER),
    query: CustomerAccountsSearchDocument,
  },
  [AuthorisationAccountTypes.OPERATIONS]: {
    label: getAccountTypeName(AuthorisationAccountTypes.OPERATIONS),
    query: OperationsAccountsSearchDocument,
  },
  [AuthorisationAccountTypes.PARTNER]: {
    label: getAccountTypeName(AuthorisationAccountTypes.PARTNER),
    query: PartnerAccountsSearchDocument,
  },
  [AuthorisationAccountTypes.PROVIDER]: {
    label: getAccountTypeName(AuthorisationAccountTypes.PROVIDER),
    query: ProviderAccountsSearchDocument,
  },
};

const emptyRecord = {
  id: null,
  name: '',
};

export const DSP_AccountSearchRelationField = ({
  accountType,
  providerTypes,
  ...props
}: DSP_AccountSearchRelationFieldProps) => {
  const query = accountTypeToLabelQueryMap[accountType].query;
  const label = accountTypeToLabelQueryMap[accountType].label;

  const clientSideFilter =
    accountType === AuthorisationAccountTypes.PROVIDER &&
    providerTypes !== undefined
      ? (result: ApolloQueryResult<ProviderAccountsSearchQuery>) =>
          filterProviderAccountsByProviderType(
            result,
            providerTypes as string[],
          )
      : undefined;

  return (
    <DSP_SearchRelationField
      id={accountType + ' account-relation-search'}
      query={query}
      label={label}
      clientSideFilter={clientSideFilter}
      getOptionLabel={searchResult => searchResult?.name || ''}
      emptyRecord={emptyRecord}
      {...props}
    />
  );
};

export {
  //
  /** @deprecated use DSP_AccountSearchRelationFieldProps instead*/
  DSP_AccountSearchRelationFieldProps as AccountSearchRelationFieldProps, //
  /** @deprecated use DSP_AccountSearchRelationField instead*/
  DSP_AccountSearchRelationField as AccountSearchRelationField,
};
