import React, {ReactNode} from 'react';

import LegacyFormContext from 'app/stores/FormContext';
import * as R from 'ramda';
import {
  FormContextProps as LegacyFormContextProps,
  FormMachineContext,
} from 'utils/stores/types';

import {useFormContext, DSP_FormContextProps} from '../useFormContext';

interface DSP_LegacyFormProviderProps {
  service: LegacyFormContextProps['service'];
  canSave?: boolean | ((context: FormMachineContext) => boolean);
  otherProps?: {[key: string]: unknown};
  children?: ReactNode | ((context: LegacyFormContextProps) => ReactNode);
}

interface DSP_LegacyFormProviderMockProps extends DSP_LegacyFormProviderProps {
  children?: ReactNode | ((context: DSP_FormContextProps) => ReactNode);
}

/**
 * In order to support legacy forms
 * This provider also allows for the use of children as a function which will be deprecated in the design system.
 *
 * @param service
 * @param children
 * @constructor
 */
export const DSP_LegacyFormProvider = ({
  otherProps,
  children,
}: DSP_LegacyFormProviderProps) => {
  const globalFormContext = useFormContext();
  const contextValues = {
    ...globalFormContext,
    otherProps,
  };

  const renderedChildren = R.is(Function, children)
    ? children(contextValues)
    : children;

  return (
    <LegacyFormContext.Provider value={contextValues}>
      {renderedChildren}
    </LegacyFormContext.Provider>
  );
};

export const DSP_LegacyFormProviderMock = ({
  children,
}: DSP_LegacyFormProviderMockProps) => {
  const contextValues = useFormContext();

  const renderedChildren = R.is(Function, children)
    ? children(contextValues)
    : children;

  return renderedChildren;
};
