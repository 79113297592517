import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/assets/:assetDetailId/peakHistoricalVehicle',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AssetPeakHistoricalVehicle" */ './AssetPeakHistoricalVehicle'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Peak historical vehicle analysis',
  authorisation: {
    apiKey: 'AssetDetailAratPeakHistRefEffectsWithRelations.find',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'partnerSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  menuIcon: WebAsset,
  sections: [
    {
      content: 'Assets',
      to: '../..',
    },
    {
      content: 'Asset',
      to: '..',
      state: {'Manage asset': 'Test Vehicles'},
    },
  ],
};
