import React from 'react';

import {DSP_WizardProvider} from '../WizardProvider';

import {useWizardLayout, DSP_UseWizardLayoutArgs} from './useWizardLayout';
import {DSP_WizardLayoutView} from './WizardLayout.View';

export type DSP_WizardLayoutProps = DSP_UseWizardLayoutArgs;
export const DSP_WizardLayout = (props: DSP_WizardLayoutProps) => {
  const {onStepChange, ...viewProps} = useWizardLayout(props);

  return (
    <DSP_WizardProvider onStepChange={onStepChange}>
      <DSP_WizardLayoutView onStepChange={onStepChange} {...viewProps} />
    </DSP_WizardProvider>
  );
};

DSP_WizardLayout.displayName = 'DSP_WizardLayout';
