import React from 'react';

import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';

import {DSL_UseGridItemStyles} from '../../../';
import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_FormControl} from '../FormControl';

export type DSL_BaseInputProps = DSL_UseGridItemStyles &
  Omit<
    MuiTextFieldProps,
    | 'variant'
    | 'children'
    | 'margin'
    | 'color'
    | 'size'
    | 'fullWidth'
    | 'select'
    | 'SelectProps'
    | 'defaultValue'
    | DSL_RestrictedVisualProps
  > & {
    /** @deprecated only added for legacy support */
    size?: MuiTextFieldProps['size'];
    useLabelForValidationErrors?: boolean;
  };

export const DSL_BaseInput = ({
  size = 'medium',
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: DSL_BaseInputProps) => {
  return (
    <DSL_FormControl span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <MuiTextField {...props} size={size} fullWidth />
    </DSL_FormControl>
  );
};

DSL_BaseInput.displayName = 'DSL_BaseInput';

export {
  //
  /** @deprecated use DSL_BaseInputProps instead*/
  DSL_BaseInputProps as BaseInputProps, //
  /** @deprecated use DSL_BaseInput instead*/
  DSL_BaseInput as BaseInput,
};
