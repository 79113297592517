import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/applications/establish/start',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationEstablishStart" */ './AccreditationEstablishStart.Connector'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Establish Accreditation',
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.create',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
