import React from 'react';

import {DSL_RouterLink} from '../../../../../Links/RouterLink';
import {
  DSL_BaseLinkCardActionArea,
  DSL_BaseLinkCardActionAreaProps,
} from '../BaseLinkCardActionArea';

export type DSL_RouterLinkCardActionAreaProps = Omit<
  DSL_BaseLinkCardActionAreaProps,
  'LinkComponent'
>;

export const DSL_RouterLinkCardActionArea = (
  props: DSL_RouterLinkCardActionAreaProps,
) => {
  return (
    <DSL_BaseLinkCardActionArea LinkComponent={DSL_RouterLink} {...props} />
  );
};

DSL_RouterLinkCardActionArea.displayName = 'DSL_RouterLinkCardActionArea';

export {
  //
  /** @deprecated use DSL_RouterLinkCardActionAreaProps instead*/
  DSL_RouterLinkCardActionAreaProps as RouterLinkCardActionAreaProps, //
  /** @deprecated use DSL_RouterLinkCardActionArea instead*/
  DSL_RouterLinkCardActionArea as RouterLinkCardActionArea,
};
