import DataUsage from '@mui/icons-material/Dashboard';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessProductDocumentGenerateds',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductDocumentGenerateds" */ './AccessProductDocumentGenerated'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Compatible vehicle networks',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'AccessProductDocumentGeneratedWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialSupervisor',
      },
      {
        role: 'operationsAccessSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};
