import React from 'react';

import {DSL_BaseCard, DSL_BaseCardProps} from '../BaseCard';

export type DSL_OutlinedCardProps = Omit<
  DSL_BaseCardProps,
  'variant' | 'elevation' | 'raised'
>;

export const DSL_OutlinedCard = (props: DSL_OutlinedCardProps) => {
  return (
    <DSL_BaseCard data-name={'outlined-card'} variant={'outlined'} {...props} />
  );
};

DSL_OutlinedCard.displayName = 'DSL_OutlinedCard';

export {
  //
  /** @deprecated use DSL_OutlinedCardProps instead*/
  DSL_OutlinedCardProps as OutlinedCardProps, //
  /** @deprecated use DSL_OutlinedCard instead*/
  DSL_OutlinedCard as OutlinedCard,
};
