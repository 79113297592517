import React from 'react';

import {RouteBlocker} from 'app/router/components/RouteBlocker';
import SubmissionRedirect from 'app/router/components/SubmissionRedirect';

import {DSP_FormProvider, DSP_FormProviderProps} from '../FormProvider';

import {DSP_WizardLayout, DSP_WizardLayoutProps} from './WizardLayout';

export interface DSP_WizardProps
  extends DSP_FormProviderProps,
    DSP_WizardLayoutProps {
  submissionRedirectPagePath?: string;
}

export const DSP_Wizard = ({
  title,
  submitConfirmMessage,
  tabSteps,
  service,
  otherProps,
  canSave,
  onSubmit,
  recordId,
  legacySupport,
  actionItems,
  pageInfo,
  submissionRedirectPagePath,
  ...formPageContainerProps
}: DSP_WizardProps) => {
  return (
    <DSP_FormProvider
      service={service}
      otherProps={otherProps}
      canSave={canSave}
      onSubmit={onSubmit}
      legacySupport={legacySupport}
      key={recordId}
      submitConfirmMessage={submitConfirmMessage}
    >
      <DSP_WizardLayout
        title={title}
        actionItems={actionItems}
        pageInfo={pageInfo}
        tabSteps={tabSteps}
        recordId={recordId}
        {...formPageContainerProps}
      />
      <RouteBlocker title={title} />
      <SubmissionRedirect pagePath={submissionRedirectPagePath} />
    </DSP_FormProvider>
  );
};

DSP_Wizard.displayName = 'DSP_Wizard';

export {
  //
  /** @deprecated use DSP_WizardProps instead*/
  DSP_WizardProps as WizardProps, //
  /** @deprecated use DSP_Wizard instead*/
  DSP_Wizard as Wizard,
};
